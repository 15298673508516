import linkIcon from "../images/link.svg";

function ExpiredPage() {

    return (
        <div>
            <div className="position-relative w-screen min-h-screen">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="font-sen-56-700">
                                <div className="text-red-600">
                                    <img width='80' src={linkIcon} alt=""/>
                                </div>
                                <div className='mt-3'>
                                    Oops!
                                </div>
                            </div>
                            <div className="font-inter-20-400 mt-3">
                                The link seems to have expired. <br/>
                                No worries, you will find the
                                report as an attachment in the email we sent you.
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    );
}

export default ExpiredPage;
