import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {useState} from "react";
import bigLogo from '../images/big-logo.svg'
import smallLogo from '../images/small-logo.svg'
import {LinkContainer} from 'react-router-bootstrap'

function Header() {

    const [scrolledStyle, setScrolledStyle] = useState(false)
    const changeNav = () => {
        if (window.scrollY>=90){
            setScrolledStyle(true)
        } else {
            setScrolledStyle(false)
        }
    }
    window.addEventListener('scroll', changeNav)
    return (
        <Navbar fixed="top" expand="lg" className={scrolledStyle ? 'nav-scrolled bg-white p-0': 'nav-simple bg-white p-0'}>
            <Container fluid className="align-items-start w-screen h-69px">
                <Navbar.Brand className="pt-0">
                    <LinkContainer to="/">
                        <Nav.Link className="position-relative">
                            <img className="big-logo" src={bigLogo} alt=""/>
                            <img className="small-logo" src={smallLogo} alt=""/>
                            <div className="text-logo">
                                <div className="first-test-logo">Online</div>
                                <div className="second-text-logo">Shogun</div>
                            </div>
                        </Nav.Link>
                    </LinkContainer>

                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" className='mt-3'/>
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="ms-auto mt-3"
                        navbarScroll
                    >
                        <LinkContainer to="/">
                            <Nav.Link eventKey="home">Home <span className="border-b-nav-link"></span></Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/about">
                            <Nav.Link eventKey="about">About <span className="border-b-nav-link"></span></Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/contact">
                            <Nav.Link eventKey="contact">Contact us <span className="border-b-nav-link"></span></Nav.Link>
                        </LinkContainer>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;
