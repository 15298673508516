import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../images/navbar-brand.svg'
import {LinkContainer} from 'react-router-bootstrap'

function SecondHeader() {

    return (
        <Navbar expand="lg" className='nav-second bg-white p-0'>
            <Container fluid className="align-items-start w-screen h-69px">
                <Navbar.Brand className="pt-2">
                    <LinkContainer to="/">
                        <Nav.Link className="position-relative">
                            <img className="w-100" src={logo} alt=""/>
                        </Nav.Link>
                    </LinkContainer>

                </Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export default SecondHeader;
