import React, {useState} from "react";
import validator from 'validator'
import {RxEnvelopeClosed} from 'react-icons/rx';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import confirmationEmail from "../images/confirmation-email.svg"
import {Spinner} from "react-bootstrap";

const RegisterEmail = () => {

    const [emailError, setEmailError] = useState(true)
    const [loading, setLoading] = useState(true);
    const [isEmailed, setIsEmailed] = useState(false)
    const [email, setEmail] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => setModalShow(false);
    let handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        setModalShow(true)
        const body = {
            email: email,
        };

        axios.post(process.env.REACT_APP_API_URL + "/register", body)
            .then(function (response) {
                setIsEmailed(true)
                setLoading(false)
            })
            .catch(function (error) {
                setIsEmailed(false)
                setLoading(false)
            });
    };
    const validateEmail = (e) => {
        var email = e.target.value
        setEmail(email)
        if (validator.isEmail(email)) {
            setEmailError(false)
        } else {
            setEmailError(true)
        }
    }
    return (
        <div>
            <div className="container-email">
                <div className="email-register me-lg-2">
                    <span className="icon"><RxEnvelopeClosed size={28}/></span>
                    <input placeholder="Email address" type="text" id="userEmail" value={email}
                           onChange={(e) => validateEmail(e)}></input>
                </div>
                <button className="btn btn-theme-orange mt-smm-1" disabled={emailError === true} onClick={handleSubmit}>Submit
                </button>
            </div>
            <Modal show={modalShow} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className={loading ? 'd-block' : 'd-none'}>
                        <div className="w-screen text-center">
                            <Spinner animation="border"/>
                        </div>
                    </div>
                    <div className={loading ? 'd-none' : 'd-block'}>
                        <div className={isEmailed? 'd-none': 'd-block'}>
                            <div className='font-sen-28-400 text-danger text-center'>
                                Something went wrong!
                            </div>
                            <div className='font-inter-16-400 text-center'>
                                Please try again
                            </div>
                        </div>
                        <div className={isEmailed? 'd-block': 'd-none'}>
                            <div className='text-center'>
                                <img src={confirmationEmail} alt=""/>
                            </div>
                            <div className="font-sen-28-400 mt-3 text-center">
                                Confirm email
                            </div>
                            <div className='font-inter-16-400 mt-3 text-center'>
                                To download the free "Wealth creation strategy" report we would like to verify your email.
                            </div>
                            <div className='font-inter-16-400 mt-2 text-center'>
                                We have sent an email to <span style={{color: '#216BC2'}}>{email}</span>. In the email, click on
                                the “Confirm” button to verify your email address.
                            </div>
                            <div className='font-inter-12-400 mt-4 text-center'>
                                If you don’t see the email in your inbox, please check your spam or junk folder.
                            </div>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
        </div>
    );
}

export default RegisterEmail
