import SecondHeader from "../../pages/SecondHeader";
import TreesBg from "../../components/TreesBg";
import {LinkContainer} from "react-router-bootstrap";
import sam3 from "../../images/sam3.svg";

function Coaching() {

    return (
        <div>
            <SecondHeader/>
            <div className='min-h-screen'>
                <div>
                    <div className="wrapper-second">
                        <div className="w-screen position-relative text-center">
                            <img className='max-w-sam' src={sam3} alt=""/>
                            <div className='font-sen-56-700 text-center mt-4 position-relative'>
                                COMING <span className='text-red-600'>SOON</span>
                            </div>
                            <div className='mt-5'>
                                <LinkContainer to={"/topMethods"}>
                                    <button className="btn btn-theme-orange hover-btn-orange-to-black">Top Methods
                                    </button>
                                </LinkContainer>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <TreesBg></TreesBg>

        </div>

    );
}

export default Coaching;
