import Carousel from 'react-bootstrap/Carousel';
import sam1 from '../images/sam1.svg'
import sam2 from '../images/sam2.svg'
import sam3 from '../images/sam3.svg'

function MainCarousel() {
    return (
        <Carousel className="carousel-height" style={{zIndex: 1}}>
            <Carousel.Item>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="font-inter-24-400">
                                Make money online and build your internet empire!
                            </div>
                            <div className="font-sen-56-700 mt-3">
                                Do you dream of financial freedom and a reliable income?
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img className="w-100" src={sam1} alt=""/>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="font-inter-24-400">
                                Make money online and build your internet empire!
                            </div>
                            <div className="font-sen-56-700 mt-3">
                                Are you looking for best ways to bring in cash, or trying to replace a soul-sucking job with a business?
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img className="w-100" src={sam2} alt=""/>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="font-inter-24-400">
                                Make money online and build your internet empire!
                            </div>
                            <div className="font-sen-56-700 mt-3">
                                Are you ready to become
                                a top 1% earner?
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img className="w-100" src={sam3} alt=""/>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
    );
}

export default MainCarousel;
