import mountain from "../images/mountain.svg";
import trees from "../images/trees-white.svg";
import pdfCover from "../images/pdf-cover.svg"
import lightBox from "../images/box-light.png"
import SecondHeader from "./SecondHeader";
import React, {useEffect, useState} from 'react'
import {Spinner} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {triggerBase64Download} from 'common-base64-downloader-react';
import axios from "axios";
import ExpiredPage from "./ExpiredPage";
import TreesBg from "../components/TreesBg";

function ReportPdf() {
    const [pdf, setPdf] = useState('');
    const [urlId, setUrlId] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        const uuid = window.location.pathname.split("/").pop()
        axios.get(process.env.REACT_APP_API_URL + "/report/" + uuid)
            .then(function (response) {
                setUrlId(uuid)
                setPdf(response.data.pdf)
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
            });


    }, [])
    return (
        <div>
            <SecondHeader/>
            <div className="wrapper-second min-h-screen">
                <div className={loading ? 'd-block' : 'd-none'}>
                    <div className="w-screen text-center">
                        <Spinner animation="border"/>
                    </div>
                </div>
                <div className={loading ? 'd-none' : 'd-block'}>
                    <div className={pdf === '' ? 'd-block' : 'd-none'}>
                        <ExpiredPage/>
                    </div>
                    <div className={pdf !== '' ? 'd-block' : 'd-none'}>
                        <div className="w-screen">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="font-sen-56-700">
                                            <div className="text-red-600">
                                                Thank you
                                            </div>
                                            <div>
                                                for getting the Online Shogun Multiple Stream Of Income report
                                            </div>
                                        </div>
                                        <div className="font-inter-20-400 mt-3">
                                            where we show you multiple methods used to make money online.
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className='d-flex justify-content-center h-100 align-items-center'>
                                            <div className="bg-gray700 p-4 position-relative mt-small-3">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-md-4 ps-lg-0">
                                                            <div>

                                                                <img className="w-100" src={pdfCover} alt=""/>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div>
                                                                <div
                                                                    className="font-inter-20-400 text-white mt-small-3">
                                                                    Here is the link to download your report
                                                                </div>
                                                                <button
                                                                    onClick={() => triggerBase64Download(pdf, 'OnlineShogun-report')}
                                                                    className="btn btn-theme-orange hover-btn-orange-to-black mt-4">DOWNLOAD PDF
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" w-screen position-relative mt-some">
                            <div style={{maxWidth: "912px"}} className="m-auto">
                                <div className="bg-white my-3 font-sen-28-400 px-small-3 text-lg-center mt-lg-5">
                                    If you like the information in the report,
                                </div>
                                <div className="bg-red700 p-4 mt-4">
                                    <div className="container-fluid">
                                        <div className="row align-items-center">
                                            <div className="col-md-2">
                                                <img className="w-light-box" src={lightBox} alt=""/>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="font-inter-24-400 text-white mt-small-3">
                                                    You might like to take a look at our other products
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="mt-small-3">
                                                    <LinkContainer to={"/topMethods/"+ urlId}>
                                                        <button className="btn btn-theme-dark">Here</button>
                                                    </LinkContainer>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <TreesBg/>


        </div>

    );
}

export default ReportPdf;
