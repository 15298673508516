import pdf from "../images/pdf-cover.svg";
import Form from "react-bootstrap/Form";
import ReportEmail from "./ReportEmail";
import Modal from "react-bootstrap/Modal";
import React from "react";
import {LinkContainer} from "react-router-bootstrap";

const NotPaidUser = () => {
    return(
            <Modal.Body>
                <div className="font-sen-28-400 mt-4 text-center text-red-600">
                    Access restricted!
                </div>
                <div className='font-inter-16-400 mt-4'>
                    Our various offering information is exclusive to customers who have bought the Online Shogun "Multiple Streams of Income" Report.
                </div>
                <div className="p-gr-box bg-gray700 mt-4">
                    <div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6 mt-small-3">
                                    <div className="d-flex h-100">
                                        <img src={pdf} className='w-100 align-items-center' alt=""/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className='ps-lg-3To'>
                                        <div className="font-inter-20-400 text-white">
                                            Don't miss out. <br/>
                                            Get it now!
                                        </div>
                                        <div className='font-inter-14-400 text-white mt-lg-3 mt-small-3 '>
                                            This comprehensive report contains multiple proven ways of making
                                            money online, all in one place.
                                        </div>
                                        <div className="mt-3">
                                            <LinkContainer to="/report">
                                                <button className="btn btn-theme-orange hover-btn-orange-to-black btn-theme-orange-small">BUY</button>
                                            </LinkContainer>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='font-inter-16-400 mt-4'>
                    Have you already bought it? <br/>
                    Enter your email below to get access.
                </div>
                <Form className="mt-3">
                    <ReportEmail/>
                </Form>



            </Modal.Body>
    )
}
export default NotPaidUser
