
import SecondHeader from "../../pages/SecondHeader";
import React, {useEffect, useState} from 'react'
import TreesBg from "../../components/TreesBg";


function ToneSuccess() {
    const [email, setEmail] = useState('');
    useEffect(() => {
        window.scrollTo(0, 0);
        setEmail(window.location.pathname.split("/").pop())

    }, [])
    return (
        <div>
            <SecondHeader/>
            <div className='min-h-screen'>
                <div>
                    <div className="wrapper-second">
                        <div className="w-screen position-relative">
                            <div className='font-inter-20-400 mt-4'>Thank you for your purchase </div>
                            <div className='font-inter-20-400 mt-4'>We sent an email to <span style={{color: '#216BC2'}}>{email}</span> containing the links </div>


                        </div>
                    </div>
                </div>
            </div>
            <TreesBg></TreesBg>

        </div>

    );
}

export default ToneSuccess;
