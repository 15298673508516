import method1 from "../images/method1.svg";
import method2 from "../images/method2.svg";
import method3 from "../images/method3.svg";
import method4 from "../images/method4.svg";
import React, {useEffect, useState} from "react";
import SecondHeader from "./SecondHeader";
import axios from "axios";
import {Spinner} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {LinkContainer} from "react-router-bootstrap";
import TreesBg from "../components/TreesBg";
import NotPaidUser from "../components/NotPaidUser";

function TopMethods() {
    const [loading, setLoading] = useState(true);
    const [uuid, setUuid] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        const uuid = window.location.pathname.split("/").pop()
        setUuid(window.location.pathname.split("/").pop())
        if (uuid.toLowerCase() === 'topmethods' || uuid === '') {
            setLoading(false)
            setModalOpen(true)
        } else {
            axios.get(process.env.REACT_APP_API_URL + "/top-methods/" + uuid)
                .then(function (response) {
                    setModalOpen(false)
                    setLoading(false)
                })
                .catch(function (error) {
                    setModalOpen(true)
                    setLoading(false)
                });
        }


    }, [])
    return (
        <div>
            <SecondHeader/>
            <Modal show={modalOpen} backdrop="static"
                   keyboard={false}>
                <NotPaidUser/>

            </Modal>
            <div className="wrapper-second">
                <div className={loading ? 'd-block' : 'd-none'}>
                    <div className="w-screen text-center min-h-90">
                        <Spinner animation="border"/>
                    </div>
                </div>
                <div className={loading ? 'd-none' : 'd-block'}>
                    <div className="w-screen">
                        <div className="container-fluid p-method">
                            <div className="row">
                                <div className="col-12">
                                    <div className='font-sen-40-700 mb-lg-5'>
                                        Top Online Money Making Methods
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <LinkContainer to={"/tone/"+uuid}>
                                        <div className='position-relative mt-small-3 cursor-pointer'>
                                            <img className='w-100' src={method1} alt=""/>
                                            <div className='method-img-sub'>Trading</div>
                                        </div>
                                    </LinkContainer>
                                </div>
                                <div className="col-md-3">
                                        <div className='position-relative mt-small-3'>
                                            <img className='w-100' src={method2} alt=""/>
                                            <div className='method-img-sub'>Freelancing</div>
                                            <div className="coming-soon">
                                                <div className="font-inter-24-700 text-white">COMING SOON</div>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-md-3">
                                        <div className='position-relative mt-small-3'>
                                            <img className='w-100' src={method3} alt=""/>
                                            <div className='method-img-sub'>Marketing</div>
                                            <div className="coming-soon">
                                                <div className="font-inter-24-700 text-white">COMING SOON</div>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-md-3">
                                        <div className='position-relative mt-small-3 mb-smm-3'>
                                            <img className='w-100' src={method4} alt=""/>
                                            <div className='method-img-sub'>Coaching</div>
                                            <div className="coming-soon">
                                                <div className="font-inter-24-700 text-white">COMING SOON</div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className='font-sen-56-700 text-center mt-coming position-relative'>*/}
                    {/*    COMING <span className='text-red-600'>SOON</span>*/}
                    {/*</div>*/}

                </div>
            </div>
            <TreesBg/>

        </div>

    );
}

export default TopMethods;
