import React, {useState} from "react";
import validator from 'validator'
import {RxEnvelopeClosed} from 'react-icons/rx';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import confirmationEmail from "../images/confirmation-email.svg"
import {Spinner} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";

const ReportEmail = () => {

    const [emailError, setEmailError] = useState(true)
    const [loading, setLoading] = useState(true);
    const [apiStatus, setApiStatus] = useState(200)
    const [email, setEmail] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => setModalShow(false);
    let handleSubmit = async (e) => {
        e.preventDefault();
        setModalShow(true)
        const body = {
            email: email,
        };

        axios.get(process.env.REACT_APP_API_URL + "/top-methods-email/" + email)
            .then(function (response) {
                setApiStatus(200)
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
                if (error.response.status === 400){
                    setApiStatus(400)
                }
                if (error.response.status === 500){
                    setApiStatus(500)
                }
            });
    };
    const validateEmail = (e) => {
        var email = e.target.value
        setEmail(email)
        if (validator.isEmail(email)) {
            setEmailError(false)
        } else {
            setEmailError(true)
        }
    }
    return (
        <div>
            <div className="container-email justify-content-center">
                <div className="email-register report-register me-lg-2">
                    <span className="icon"><RxEnvelopeClosed size={23}/></span>
                    <input placeholder="Email address" type="text" id="userEmail" value={email}
                           onChange={(e) => validateEmail(e)}></input>
                </div>
                <button className="btn btn-theme-dark btn-theme-dark-small mt-smm-1" disabled={emailError === true} onClick={handleSubmit}>Submit
                </button>
            </div>
            <Modal show={modalShow} onHide={handleClose} className="report-modal">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className={loading ? 'd-block' : 'd-none'}>
                        <div className="w-screen text-center">
                            <Spinner animation="border"/>
                        </div>
                    </div>
                    <div className={loading ? 'd-none' : 'd-block'}>
                        <div className={apiStatus === 500? 'd-block': 'd-none'}>
                            <div className='font-sen-28-400 text-danger text-center'>
                                Something went wrong!
                            </div>
                            <div className='font-inter-16-400 text-center'>
                                Please try again
                            </div>
                        </div>
                        <div className={apiStatus === 400? 'd-block': 'd-none'}>
                            <div className='font-sen-28-400 text-center'>
                                Your email address is not registered as a user who can access this page.
                            </div>
                            <div className='font-sen-28-400 text-center mt-4'>
                                Please refer to our Multiple Streams Of Income Report page.
                            </div>
                            <div className='text-center mt-4'>
                                <LinkContainer to="/report">
                                    <button className="btn btn-theme-dark hover-btn-black-to-orange">Multiple Streams Of Income Report
                                    </button>
                                </LinkContainer>
                            </div>

                        </div>
                        <div className={apiStatus === 200? 'd-block': 'd-none'}>
                            <div className='text-center'>
                                <img src={confirmationEmail} alt=""/>
                            </div>
                            <div className="font-sen-28-400 mt-3 text-center">
                                Get a new link
                            </div>
                            <div className='font-inter-16-400 mt-3 text-center'>
                                To access the Online Shogun top methods page, we need to verify your email.
                            </div>
                            <div className='font-inter-16-400 mt-2 text-center'>
                                We have sent an email to <span style={{color: '#216BC2'}}>{email}</span>. <br/> In the email, click on
                                the “Top methods” button.
                            </div>
                            <div className='font-inter-12-400 mt-4 text-center'>
                                If you don’t see the email in your inbox, please check your spam or junk folder.
                            </div>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
        </div>
    );
}

export default ReportEmail
