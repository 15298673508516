import Form from 'react-bootstrap/Form';
import InputGroup from "react-bootstrap/InputGroup";
import {RxEnvelopeClosed} from "react-icons/rx";
import React, {useEffect, useState} from "react";
import SecondHeader from "./SecondHeader";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { AiOutlineWarning } from 'react-icons/ai';
import TreesBg from "../components/TreesBg";
import Modal from "react-bootstrap/Modal";
import {Spinner} from "react-bootstrap";
import check from "../images/clipboard-check.svg";

function RegisterForm() {
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate()
    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isEmailed, setIsEmailed] = useState(false)

    const handleClose = () => setModalShow(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const handleCancel = (event) => {
        event.preventDefault();
        event.stopPropagation();
        document.getElementById("register-form").reset();
    }
    const handleSubmit = async (event) => {
        setLoading(true)
        setModalShow(true)
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity()) {
            const answers = event.target.elements
            const body = {
                name: answers.name.value,
                email: answers.email.value,
                linkedin: answers.linkedin.value,
                spareTime: answers.timePerWeek.value,
                employmentStatus: answers.employment.value,
                income: answers.income.value,
                savings: answers.saving.value,
                strongestSkill: answers.skill.value,
                weakestSkill: answers.businessSkill.value
            };

            axios.post(process.env.REACT_APP_API_URL + "/registerForm", body)
                .then(function (response) {
                    setIsEmailed(true)
                    setLoading(false)
                })
                .catch(function (error) {
                    setIsEmailed(false)
                    setLoading(false)
                    navigate('/thanksSubmit/error');
                });
        }else {
            setValidated(true);
            window.scrollTo(0, 0);
        }


    };
    return (
        <div>
            <SecondHeader/>
            <Modal show={modalShow} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className={loading ? 'd-block' : 'd-none'}>
                        <div className="w-screen text-center">
                            <Spinner animation="border"/>
                        </div>
                    </div>
                    <div className={loading ? 'd-none' : 'd-block'}>
                        <div className={isEmailed? 'd-none': 'd-block'}>
                            <div className='font-sen-28-400 text-danger text-center'>
                                Something went wrong!
                            </div>
                            <div className='font-inter-16-400 text-center'>
                                Please try again
                            </div>
                        </div>
                        <div className={isEmailed? 'd-block': 'd-none'}>
                            <div className='text-center'>
                                <img className='w-check' src={check} alt=""/>
                            </div>
                            <div className="font-sen-28-400 mt-3 text-center">
                                Thank you for applying to work directly with us.
                            </div>
                            <div className='font-inter-16-400 mt-3 text-center'>
                                We will be in touch if you are selected. <br/>
                                Meanwhile please check your email for our recommendation regarding the best method of making money online based on your profile.
                            </div>

                            <div className='font-inter-12-400 mt-4 text-center'>
                                If you don’t see the email in your inbox, please check your spam or junk folder.
                            </div>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
            <div className="wrapper-second bg-form position-relative">
                <div className="w-screen">
                    <Form noValidate validated={validated} onSubmit={handleSubmit} id='register-form'>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className='font-sen-40-700 text-lg-center'>
                                        Apply To Work Directly With US
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='max-w-form'>
                            <div className="container-fluid mt-lg-5 mt-3">
                                <div className={validated? 'd-block': 'd-none'}>
                                    <div className='incomplete'>
                                        <span className='pe-3'><AiOutlineWarning size={40}/></span> <span className='font-inter-16-400'>There was an error with your submission</span>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-12">
                                        <div className='font-inter-14-400 my-2'>
                                            <span className='required-star'>*</span>
                                            Indicates required field
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className='bg-white p-form position-relative form-shadow'>
                                            <Form.Group className="mb-3" controlId="registerForm.name">
                                                <Form.Label>Your name</Form.Label>
                                                <Form.Control name="name" className="h-50" type="text" placeholder="Type here"
                                                              required/>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className='bg-white p-form position-relative form-shadow'>
                                            <Form.Group className="email-form mb-3" controlId="registerForm.email">
                                                <Form.Label>
                                                    Email address
                                                </Form.Label>
                                                <InputGroup>
                                                    <InputGroup.Text><RxEnvelopeClosed/></InputGroup.Text>
                                                    <Form.Control name="email"
                                                        className="h-50"
                                                        type="email"
                                                        placeholder="Email address"
                                                        required
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className='bg-white p-form position-relative form-shadow'>
                                            <div className='font-inter-20-400'>
                                                What is your LinkedIn account?
                                            </div>
                                            <Form.Group className="mb-3 mt-3" controlId="registerForm.linkedin">
                                                <Form.Label>Copy and paste the link to your account below.</Form.Label>
                                                <Form.Control name="linkedin" className="h-50" type="text" placeholder="Type here"/>
                                            </Form.Group>
                                            <div className='font-inter-12-400'>
                                                If you do not have a LinkedIn account, make one at www.linkedin.com - a
                                                lot of professional networking is done on LinkedIn so this will be
                                                helpful.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className='bg-white p-form position-relative form-shadow'>
                                            <div className='font-inter-20-400 mb-4'>
                                                How much spare time do you have per week? <span
                                                className='required-star'>*</span>
                                            </div>
                                            <Form.Group className="mb-3 mt-3" controlId="registerForm.timePerWeek">
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='form-time-5'
                                                            label='Under 5 hours'
                                                            value="Under 5 hours"
                                                            name='timePerWeek'
                                                            required
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='form-time-5-10'
                                                            label='5-10 hours'
                                                            value="5-10 hours"
                                                            name='timePerWeek'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='form-time-10-20'
                                                            label='10-20 hours'
                                                            value='10-20 hours'
                                                            name='timePerWeek'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='form-time-over20'
                                                            label='over20 hours'
                                                            value='over20 hours'
                                                            name='timePerWeek'
                                                />
                                            </Form.Group>

                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className='bg-white p-form position-relative form-shadow'>
                                            <div className='font-inter-20-400 mb-4'>
                                                What is your current employment status? <span
                                                className='required-star'>*</span>
                                            </div>
                                            <Form.Group className="mb-3 mt-3" controlId="registerForm.employment">
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='employment-full-time'
                                                            label='Employed full-time'
                                                            value='Employed full-time'
                                                            name='employment'
                                                            required
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='employment-part-time'
                                                            label='Employed part-time'
                                                            value='Employed part-time'
                                                            name='employment'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='employment-student'
                                                            label='Student'
                                                            value='Student'
                                                            name='employment'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='employment-unemployed'
                                                            label='Unemployed'
                                                            value='Unemployed'
                                                            name='employment'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='employment-self-employed'
                                                            label='Self-employed'
                                                            value='Self-employed'
                                                            name='employment'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='employment-business-owner'
                                                            label='Business Owner'
                                                            value='Business Owner'
                                                            name='employment'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='employment-retired'
                                                            label='Retired'
                                                            value='Retired'
                                                            name='employment'
                                                />

                                            </Form.Group>

                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className='bg-white p-form position-relative form-shadow'>
                                            <div className='font-inter-20-400 mb-4'>
                                                What is your current income range? <span
                                                className='required-star'>*</span>
                                            </div>
                                            <Form.Group className="mb-3 mt-3" controlId="registerForm.income">
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='income-under-20'
                                                            label='Under $20,000'
                                                            value='Under $20,000'
                                                            name='income'
                                                            required
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='income-20-40'
                                                            label='$20,000-$40,000'
                                                            value='$20,000-$40,000'
                                                            name='income'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='income-40-60'
                                                            label='$40,000-$60,000'
                                                            value='$40,000-$60,000'
                                                            name='income'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='income-60-80'
                                                            label='$60,000-$80,000'
                                                            value='$60,000-$80,000'
                                                            name='income'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='income-over-80'
                                                            label='Over $80,000'
                                                            value='Over $80,000'
                                                            name='income'
                                                />
                                            </Form.Group>

                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className='bg-white p-form position-relative form-shadow'>
                                            <div className='font-inter-20-400 mb-4'>
                                                How much money do you have in savings? <span
                                                className='required-star'>*</span>
                                            </div>
                                            <Form.Group className="mb-3 mt-3" controlId="registerForm.saving">
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='saving-under-1'
                                                            label='Under $1,000'
                                                            value='Under $1,000'
                                                            name='saving'
                                                            required
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='saving-1-5'
                                                            label='$1,000-$5,000'
                                                            value='$1,000-$5,000'
                                                            name='saving'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='saving-5-10'
                                                            label='$5,000-$10,000'
                                                            value='$5,000-$10,000'
                                                            name='saving'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='saving-over-10'
                                                            label='Over $10,000'
                                                            value='Over $10,000'
                                                            name='saving'
                                                />
                                            </Form.Group>

                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className='bg-white p-form position-relative form-shadow'>
                                            <div className='font-inter-20-400 mb-4'>
                                                What is your strongest skill? <span
                                                className='required-star'>*</span>
                                            </div>
                                            <Form.Group className="mb-3 mt-3" controlId="registerForm.skill">
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='skill-sales'
                                                            label='Sales, closing deals'
                                                            value='Sales, closing deals'
                                                            name='skill'
                                                            required
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='skill-marketing'
                                                            label='Email Marketing'
                                                            value='Email Marketing'
                                                            name='skill'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='skill-media'
                                                            label='Social Media (LinkedIn, Twitter, Instagram, Facebook'
                                                            value='Social Media (LinkedIn, Twitter, Instagram, Facebook'
                                                            name='skill'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='skill-video'
                                                            label='Video Editing'
                                                            value='Video Editing'
                                                            name='skill'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='skill-design'
                                                            label='Graphic Design'
                                                            value='Graphic Design'
                                                            name='skill'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='skill-website'
                                                            label='Website building'
                                                            value='Website building'
                                                            name='skill'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='skill-ios'
                                                            label='iOS Programming'
                                                            value='iOS Programming'
                                                            name='skill'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='skill-android'
                                                            label='Android Programming'
                                                            value='Android Programming'
                                                            name='skill'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='skill-web3'
                                                            label='Web3 (bitcoin, ethereum) programming'
                                                            value='Web3 (bitcoin, ethereum) programming'
                                                            name='skill'
                                                />
                                                <Form.Check className='font-inter-14-400 mt-3 hover-pointer'
                                                            type='radio'
                                                            id='skill-other'
                                                            label='Other'
                                                            value='Other'
                                                            name='skill'
                                                />


                                            </Form.Group>

                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className='bg-white p-form position-relative form-shadow'>
                                            <div className='font-inter-20-400'>
                                                What business skill is your weakest? <span className='required-star'>*</span>
                                            </div>
                                            <Form.Group className="mb-3 mt-3" controlId="registerForm.businessSkill">
                                                <Form.Control name="businessSkill" className="h-50" type="text" placeholder="Type here" required/>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-5 mb-smm-3">
                                        <div className='d-flex justify-content-center position-relative'>
                                            <div>
                                                <button className='btn btn-theme-dark me-3' onClick={handleCancel}>Cancel</button>
                                            </div>
                                            <div>
                                                <button className='btn btn-theme-orange' type='submit'>SUBMIT</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Form>

                </div>
                <TreesBg/>

            </div>

        </div>

    );
}

export default RegisterForm;
