import Form from 'react-bootstrap/Form';
import MainCarousel from "../components/MainCarousel";
import mountain from '../images/mountain.svg'
import imgFree from '../images/free.svg'
import girl from '../images/girl.svg'
import warning from '../images/redWarning.svg'
import samAndTree from '../images/samAndTree.svg'
import trees from '../images/trees-gray.svg'
import Header from "./Header";
import React, {useEffect} from "react";
import RegisterEmail from "../components/RegisterEmail";

function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div>
            <Header/>
            <div className="wrapper">
                <div className="w-screen">
                    <MainCarousel/>
                </div>
                <div className="m-mountain-home">
                    <img className="w-100" src={mountain} alt=""/>
                </div>
                <div className="w-screen">
                    <div className="bg-gray800 p-dark-box position-relative">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="m-3">

                                        <img className="w-free-img" src={imgFree} alt=""/>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="m-3">
                                        <div className="font-sen-30-400 text-white mt-lg-5 mt-md-4">
                                            We've put together a report describing our strategy for making money online.
                                        </div>
                                        <div className="font-inter-14-400 text-white mt-3">
                                            Simply enter your email address in the form below, and you will be able to
                                            download the report for free.
                                        </div>
                                        <Form className="mt-5">
                                            <RegisterEmail/>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="w-screen p-white-box">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div>
                                    <img className="w-100" src={girl} alt=""/>
                                </div>
                            </div>
                            <div className="col-md-9 mt-small-3">
                                <div className='d-flex flex-column justify-content-center align-items-center ps-lg-5 h-100'>
                                    <div className='w-100'><div className="border-top-orange text-center"></div></div>
                                    <div className="font-inter-20-400 w-100">
                                        Online Shogun gives you
                                    </div>
                                    <div className="font-sen-48-400 mt-3">
                                        a step-by-step system to generate money online, FAST!
                                    </div>
                                    <div className="font-inter-20-400 mt-3">
                                        And each and every one of the techniques in our system gives you the ability
                                        to <b>
                                        work from home,
                                        make money while traveling, set your own schedule, and achieve financial
                                        independence!
                                    </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-screen p-dark-box text-white bg-gray800 font-inter-24-400">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1 text-center">
                                <img className="mt-3" src={warning} alt=""/>
                            </div>
                            <div className="col-md-11 mt-small-3">
                                But if you expect the usual nonsense you see online where fake “gurus” teach you how to
                                make
                                a few
                                hundred bucks a day, Online Shogun is not for you.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="position-relative p-white-box">
                    <div className="w-screen" style={{minHeight: "433px"}}>
                        <div className="samAndTree">
                            <img src={samAndTree} alt=""/>
                        </div>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="border-top-orange"></div>
                                    <div className="font-inter-20-400">
                                        Our techniques are designed to help you create
                                    </div>
                                    <div className="font-sen-48-400 mt-3">
                                        massive wealth from multiple income streams in the safest way possible
                                    </div>
                                    <div className="font-inter-20-400 mt-5">
                                        If you follow our system, you will be able to build a business dynasty and
                                        create
                                        generational wealth.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray700 pt-60">
                    <div style={{maxWidth: "994px"}} className="m-auto">
                        <div className=" text-white font-sen-28-400 text-center px-3 text-smm-left">
                            To get you started on your journey to financial freedom,
                            we've put together a report describing our strategy for making money online.
                        </div>

                        <div className="bg-white p-dark-box mt-60">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-2 p-lg-0">
                                        <div className='text-lg-center'>

                                            <img width='134' src={imgFree} alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-10 mt-lg-3 p-lg-0">
                                        <div className=' ps-lg-4'>
                                            <div className="font-inter-16-400 mt-small-3">
                                                Simply enter your email address in the form below, and you will be able
                                                to
                                                download the report for free.
                                            </div>
                                            <Form className='mt-3'>
                                                <RegisterEmail/>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" text-white font-inter-20-400 text-center px-3 mt-60 pb-smm-60">
                            Don't wait any longer to take control of your financial life, <br/>
                            Start making money online today with Online Shogun!
                        </div>
                    </div>

                </div>
                <div className='d-smm-none'>
                    <img className="w-100" src={trees} alt=""/>
                </div>
            </div>
        </div>

    );
}

export default Home;
