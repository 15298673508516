import mountain from "../images/mountain.svg";
import trees from "../images/trees-white.svg";
import sam from "../images/samThanks1.svg";
import imgFree from "../images/free.svg";
import SecondHeader from "./SecondHeader";
import React, {useEffect, useState} from 'react'
import {Spinner} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import {triggerBase64Download} from 'common-base64-downloader-react';
import axios from "axios";
import linkIcon from "../images/link.svg"
import RegisterEmail from "../components/RegisterEmail";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import TreesBg from "../components/TreesBg";

function StrategyPdf() {
    const [pdf, setPdf] = useState('');
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        window.scrollTo(0, 0);
        const uuid = window.location.pathname.split("/").pop()
        axios.get(process.env.REACT_APP_API_URL + "/strategy/" + uuid)
            .then(function (response) {
                setPdf(response.data.pdf)
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
            });


    }, [])
    return (
        <div>
            <SecondHeader/>
            <div className='min-h-screen'>
                <div className={loading ? 'd-block' : 'd-none'}>
                    <div className="wrapper-second">
                        <div className="w-screen text-center">
                            <Spinner animation="border"/>
                        </div>
                    </div>
                </div>
                <div className={loading ? 'd-none' : 'd-block'}>
                    <div className={pdf === '' ? 'd-block' : 'd-none'}>
                        <div className="wrapper-second">
                            <div className="w-screen">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="font-sen-56-700">
                                                <div className="text-red-600">
                                                    <img width='80' src={linkIcon} alt=""/>
                                                </div>
                                                <div className='mt-3'>
                                                    Oops!
                                                </div>
                                            </div>
                                            <div className="font-inter-20-400 mt-3">
                                                The link seems to have broken. <br/>
                                                please enter your email address if you want to request again.
                                            </div>
                                            <div className='mt-4'>
                                                <Form>
                                                    <RegisterEmail/>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={pdf !== '' ? 'd-block' : 'd-none'}>
                        <div className="wrapper-second">
                            <div className="w-screen">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="font-sen-56-700">
                                                <div className="text-red-600">
                                                    Thank you
                                                </div>
                                                <div>
                                                    for your interest in
                                                    Online Shogun
                                                </div>
                                            </div>
                                            <div className="font-inter-20-400 mt-3">
                                                where we teach you how to use strategy to make money online using different
                                                proven
                                                methods.
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='d-flex justify-content-center h-100 align-items-center'>
                                                <div className="bg-gray700 p-32 mt-small-3" style={{height: 'fit-content'}}>
                                                    <div className="container-fluid">
                                                        <div className="row">
                                                            <div className="col-md-4 p-lg-0">
                                                                <div>

                                                                    <img className="w-100" src={imgFree} alt=""/>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8 p-lg-0">
                                                                <div
                                                                    className='d-flex justify-content-center align-items-center h-100'>
                                                                    <div className='ps-lg-4'>
                                                                        <div
                                                                            className="font-inter-20-400 text-white mt-small-3">
                                                                            Here is the link to download your free report,
                                                                            as
                                                                            promised
                                                                        </div>
                                                                        <button
                                                                            onClick={() => triggerBase64Download(pdf, 'OnlineShogun-Strategy')}
                                                                            className="btn btn-theme-orange mt-4 hover-btn-orange-to-black">DOWNLOAD
                                                                            PDF
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" w-screen position-relative mt-some">
                                <div style={{maxWidth: "912px"}} className="m-auto">
                                    <div className="bg-white my-3 font-sen-28-400 px-small-3 mb-lg-5">
                                        If you like the information in the report, you might like to know we are currently
                                        offering
                                        the opportunity to a limited number of people to work directly with us.
                                    </div>
                                    <div className="bg-red700 pb-smm-30">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-3 p-0">
                                                    <img className="w-100" src={sam} alt=""/>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="font-inter-24-400 text-white p-lg-4 mt-3">
                                                        You will tap directly into our expertise and network if you are
                                                        accepted
                                                        into this program.
                                                    </div>
                                                    <div className="text-lg-right pe-4 mt-small-3">
                                                        <LinkContainer to="/report">
                                                            <button className="btn btn-theme-dark">Get you chance here!
                                                            </button>
                                                        </LinkContainer>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white font-inter-16-400 p-4">
                                        NOTE: Only a few will be contacted and even fewer will be accepted into the program.
                                        Anyone
                                        else can still benefit from our wide range of services and products.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TreesBg/>


        </div>

    );
}

export default StrategyPdf;
