import React, {useEffect, useState} from "react";
import SecondHeader from "./SecondHeader";
import logo from "../images/navbar-brand.svg";
import Nav from "react-bootstrap/Nav";
import {LinkContainer} from "react-router-bootstrap";

function Privacy() {
    const [active, setActive] = useState('Collection');

    function scrollTo(id) {
        setActive(id)
        document.getElementById(id).scrollIntoView({block: "center"});
    }

    useEffect(() => {
        window.scrollTo(0, 0);


    }, [])
    return (
        <div>
            <div className='d-just-mobile'>
                <SecondHeader/>
            </div>
            <div className=" px-small-3">
                <div className="w-screen">
                    <div className="terms-page">
                        <div className="sidebar-terms">
                            <div className='mb-5'>
                                <LinkContainer to="/">
                                    <Nav.Link>
                                        <img src={logo} alt=""/>
                                    </Nav.Link>
                                </LinkContainer>

                            </div>
                            <div className="terms-list mt-3">
                                <a className={active === 'Collection' ? 'active' : ''}
                                   onClick={() => scrollTo('Collection')}>Collection of Personal information</a>
                                <a onClick={() => scrollTo('Use')}
                                   className={active === "Use" ? 'active' : ''}>Use of personal information</a>
                                <a onClick={() => scrollTo('Sharing')}
                                   className={active === "Sharing" ? 'active' : ''}>Sharing of personal information</a>
                                <a onClick={() => scrollTo('Data')}
                                   className={active === "Data" ? 'active' : ''}>Data security </a>
                                <a onClick={() => scrollTo('Access')}
                                   className={active === "Access" ? 'active' : ''}>Access to personal information</a>
                                <a onClick={() => scrollTo('Cookies')}
                                   className={active === "Cookies" ? 'active' : ''}>Cookies</a>
                                <a onClick={() => scrollTo('Changes')}
                                   className={active === "Changes" ? 'active' : ''}>Changes to privacy policy</a>
                                <a onClick={() => scrollTo('Contact')}
                                   className={active === "Contact" ? 'active' : ''}>Contact information</a>
                                <div style={{marginBottom: '150px'}}></div>
                            </div>
                        </div>
                        <div className="main-terms">
                            <div className="font-inter-36-700">
                                Privacy policy
                            </div>
                            <div id="Collection" className="mt-conditions">
                                <div className="font-inter-24-400">1. Collection of personal information</div>
                                <div className="font-inter-16-400 mt-inner-conditions">The website collects personal information such
                                    as name, address, email, and payment information when users place an order or create
                                    an account.
                                </div>
                            </div>
                            <div id="Use" className="mt-conditions">
                                <div className="font-inter-24-400">2. Use of personal information</div>
                                <div className="font-inter-16-400 mt-inner-conditions">
                                    The website uses personal information to process orders, send updates, and improve
                                    the user experience. The website may also use personal information to send
                                    promotional offers.
                                </div>
                            </div>
                            <div id="Sharing" className="mt-conditions">
                                <div className="font-inter-24-400">3. Sharing of personal information</div>
                                <div className="font-inter-16-400 mt-inner-conditions">The website will not sell or rent personal
                                    information to third parties. However, the website may share personal information
                                    with third-party service providers who assist with order processing, payment
                                    processing, and other services.
                                </div>
                            </div>
                            <div id="Data" className="mt-conditions">
                                <div className="font-inter-24-400">4. Data security</div>
                                <div className="font-inter-16-400 mt-inner-conditions">The website takes measures to protect personal
                                    information, including the use of secure servers and encryption. However, no data
                                    transmission over the internet can be guaranteed to be 100% secure.
                                </div>
                            </div>
                            <div id="Access" className="mt-conditions">
                                <div className="font-inter-24-400">5. Access to personal information</div>
                                <div className="font-inter-16-400 mt-inner-conditions">Users may request to view and update their
                                    personal information at any time by contacting the website.
                                </div>
                            </div>
                            <div id="Cookies" className="mt-conditions">
                                <div className="font-inter-24-400">6. Cookies</div>
                                <div className="font-inter-16-400 mt-inner-conditions">The website may use cookies to track user
                                    activity and improve the user experience. Users may choose to disable cookies in
                                    their web browser, but doing so may affect the functionality of the website.
                                </div>
                            </div>
                            <div id="Changes" className="mt-conditions">
                                <div className="font-inter-24-400">7. Changes to privacy policy</div>
                                <div className="font-inter-16-400 mt-inner-conditions">The website reserves the right to change the
                                    privacy policy at any time without notice.
                                </div>
                            </div>
                            <div id="Contact" className="mt-conditions">
                                <div className="font-inter-24-400">8. Contact information</div>
                                <div className="font-inter-16-400 mt-inner-conditions">The website can be contacted by users at info@onlineshogun.com with questions or concerns about their personal
                                    information or the privacy policy.
                                </div>
                            </div>
                            <div style={{marginBottom: '100px'}}></div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    );
}

export default Privacy;
