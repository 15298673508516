import mountain from "../images/mountain.svg";
import trees from "../images/trees-white.svg";
import roundishImg from "../images/roundish-img.svg";
import man1 from "../images/man1.svg";
import man2 from "../images/man2.svg";
import Header from "./Header";
import React, {useEffect} from "react";
import TreesBg from "../components/TreesBg";

function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div>
            <Header/>
            <div className="wrapper">
                <div className="w-screen">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8">
                                <div className='pe-4'>
                                    <div className="border-top-orange"></div>
                                    <div className="font-sen-56-700">
                                        Adrian
                                    </div>
                                    <div className="font-inter-20-400 mt-3">
                                        is a technology and management consultant and business owner based in NYC, USA. Holding
                                        a PhD in Physics (thesis based on nuclear physics research at MIT Bates Lab) and a
                                        Masters in Computer Science from UNH, Adrian previously worked in Management Consulting
                                        for eight years,
                                    </div>
                                    <div className="font-sen-48-400 mt-5">
                                        helping Fortune 500 companies in the US and Europe build their mission critical systems.
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className='d-flex align-items-center justify-content-center h-100 mt-small-3'>
                                    <img className="w-100" src={roundishImg} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid" style={{marginTop: "90px"}}>
                        <div className="row">
                            <div className="col-12">
                                <div className="border-top-orange"></div>
                                <div className="font-inter-20-400 mb-5">
                                    Examples of his work from this period include
                                </div>
                            </div>
                            <div className="col-md-3">
                                <img className="w-100" src={man1} alt=""/>
                            </div>
                            <div className="col-md-9">
                                <div className="font-inter-20-400 mt-small-3 ps-lg-3">
                                    Dow Jones’ Wall Street Journal and Barron’s advertisement systems, and NetJets’
                                    fleet scheduling and service recovery computer programs used in production
                                    currently. Following his management consulting stint Adrian founded his own IT
                                    Company <b>Win-Win Software Solutions</b>, Inc. in 2015 in partnership with his
                                    childhood
                                    friend currently living in Sweden.
                                </div>
                                <div className="font-inter-20-400 mt-5 ps-lg-3">
                                    Win-Win Software Solutions Inc. marked Adrian’s beginning life as an entrepreneur
                                    and as such he learned to build complex software systems for clients or partners
                                    while dealing with uncertainty and rapidly changing requirements and market
                                    conditions. During this time period his passion for startups meant focusing on
                                    helping early stage entities.
                                </div>
                            </div>
                            <div className="col-12 my-5 font-inter-20-400">
                                Adrian became passionate about blockchain technology during his work with startups in the
                                New York City area and briefly joined one such startup in 2017, becoming the first Officer
                                of the company besides the two co-founders.
                            </div>

                            <div className="col-md-9">
                                <div className="font-inter-20-400 pe-lg-3">
                                    His focus was Partnerships and as Chief Commercial Officer led both Sales and Marketing
                                    departments for a brief period. It is at this point that he also started helping Block X
                                    Ventures LLC part-time. His work for Block X Ventures brought Adrian collaborations in
                                    the United States, Europe, Middle East and Asia (with a strong focus on Japan where he
                                    spent almost half his time during late 2018 - early 2020).
                                </div>
                                <div className="font-inter-20-400 mt-5 pe-lg-3">
                                    His work has included fundraising/marketing, digital asset exchange partnerships, market
                                    making, liquidity providing, tech consulting and business strategy. Block X Ventures was
                                    shut down at the end of 2020 as the COVID pandemic made travel impossible, and following
                                    interest from multiple partners Adrian decided to start his own company with a focus on
                                    the Japanese market - this is how Japan Ventures LLC was born at the end of 2021.
                                </div>
                            </div>
                            <div className="col-md-3 mt-small-3">
                                <img className="w-100" src={man2} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-screen mt-lg-5">
                    <div className="bg-gray800 p-dark-box text-white px-small-3 position-relative">
                        As the world economy is starting to shrink because of central banks everywhere tightening monetary
                        conditions, Adrian sees a need to help everyone learn how to create revenue online. With layoffs at
                        big companies accelerating in the US and globally, demand for online revenue generation is expected
                        to skyrocket. This is how Online Shogun has been born.
                    </div>

                </div>
            </div>
            <TreesBg/>
        </div>

    );
}

export default About;
