import mountain from "../images/mountain.svg";
import trees from "../images/trees-white.svg";


function TreesBg() {
    return (

            <div className="trees-bg-bottom">
                <div className='mt-mountain'>
                    <img src={mountain} alt="" className="w-100"/>
                </div>

                <div className='d-smm-none'>
                    <img className="w-100" src={trees} alt=""/>
                </div>
            </div>



    );
}

export default TreesBg;
