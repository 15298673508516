import Nav from "react-bootstrap/Nav";
import {LinkContainer} from "react-router-bootstrap";

function Footer() {
    return (
        <div>
            <footer className="footer">
                <div className="w-screen container-fluid h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-md-6">© 2023 Online Shogun. All Rights Reserved</div>
                        <div className="col-md-6">
                            <Nav className="justify-content-lg-end justify-content-smm-center">
                                <LinkContainer to="/terms">
                                    <Nav.Link className="footer-link m-smm-0">Terms of use</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/privacy">
                                    <Nav.Link className="footer-link">Privacy policy</Nav.Link>
                                </LinkContainer>

                            </Nav>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
