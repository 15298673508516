import mountain from "../images/mountain.svg";
import trees from "../images/trees-white.svg";
import check from "../images/clipboard-check.svg"
import lightBox from "../images/box-light.png"
import SecondHeader from "./SecondHeader";
import React, {useEffect, useState} from 'react'
import {LinkContainer} from "react-router-bootstrap";
import TreesBg from "../components/TreesBg";


function ThanksSubmit() {
    const [text, setText] = useState('');
    const [error, setError] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
        const type = window.location.pathname.split("/").pop()
        if (type === 'contact'){
            setText('Your contact information')
        }
        if (type === 'form'){
            setText('Your application ')
        }
        if (type === 'error') {
            setError(true)
        }

    }, [])
    return (
        <div>
            <SecondHeader/>
            <div>
                <div>
                    <div className="wrapper-second">
                        <div className="w-screen position-relative">
                            <div className={error? 'd-none': ''}>
                                <div style={{maxWidth: "912px"}} className="m-auto">
                                    <div className='p-smm-15rem'>
                                        <div className='text-lg-center'>
                                            <img className='w-check' src={check} alt=""/>
                                        </div>
                                        <div className="font-sen-40-700 text-lg-center mt-lg-5 mt-small-3">
                                            {text} <br/>
                                            <span>has been submitted successfully.</span>
                                        </div>
                                        <div className='font-inter-20-400 text-lg-center mt-lg-5 mt-small-3'>
                                            While we process it,
                                        </div>
                                    </div>
                                    <div className="bg-red700 mt-4" style={{padding: '40px'}}>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <img className="w-light-box" src={lightBox} alt=""/>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className='d-flex align-items-center h-100 justify-content-center'>
                                                        <div className="font-sen-28-400 text-white mt-small-3">
                                                            You can now look inside Online Shogun!
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-2">
                                                    <div className='d-lg-flex align-items-center h-100 justify-content-lg-center mt-small-3'>
                                                        <LinkContainer to="/topMethods">
                                                            <button className="btn btn-theme-dark">Take a look</button>
                                                        </LinkContainer>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={error? '': 'd-none'}>
                                <div style={{maxWidth: "912px"}} className="m-auto">
                                    <div className="font-sen-40-700 text-center mt-5 text-danger">
                                        Unfortunately an error occurred
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className='mt-mountain-submit'>
                            <img src={mountain} alt="" className="w-100"/>
                        </div>

                        <div className='d-smm-none'>
                            <img className="w-100" src={trees} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <TreesBg/>

        </div>

    );
}

export default ThanksSubmit;
