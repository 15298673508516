import SecondHeader from "./SecondHeader";
import React, {useEffect} from 'react'
import TreesBg from "../components/TreesBg";
import {LinkContainer} from "react-router-bootstrap";


function PageNotFound() {
    useEffect(() => {
        window.scrollTo(0, 0);

    }, [])
    return (
        <div>
            <SecondHeader/>
            <div className='min-h-screen'>
                <div>
                    <div className="wrapper-second">
                        <div className="w-screen position-relative">
                            <div className="font-sen-56-700 text-red-600">404</div>
                            <div className='font-inter-20-400 mt-4'>The page you are looking for is not found</div>
                            <div className='mt-4'>
                                <LinkContainer to="/">
                                    <button className="btn btn-theme-orange hover-btn-orange-to-black">Home page
                                    </button>
                                </LinkContainer>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <TreesBg></TreesBg>

        </div>

    );
}

export default PageNotFound;
