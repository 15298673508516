import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Footer from "./pages/Footer";
import Terms from "./pages/Terms";
import StrategyPdf from "./pages/StrategyPdf";
import Report from "./pages/Report";
import RegisterForm from "./pages/RegisterForm";
import ReportPdf from "./pages/ReportPdf";
import TopMethods from "./pages/TopMethods";
import ThanksSubmit from "./pages/ThanksSubmit";
import Privacy from "./pages/Privacy";
import PageNotFound from "./pages/PageNotFound";
import Tone from "./methods/Trading/Tone";
import ToneSuccess from "./methods/Trading/ToneSuccess";
import Coaching from "./methods/Coaching/Coaching";
import Freelancing from "./methods/Freelancing/Freelancing";
import Marketing from "./methods/Marketing/Marketing";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route exact path="/" element={<Home/>} />
                <Route path="/contact" element={<Contact/>} />
                <Route path="/about" element={<About/>} />
                <Route path="/terms" element={<Terms/>} />
                <Route path="/privacy" element={<Privacy/>} />
                <Route path="/strategyPdf/:uuid" element={<StrategyPdf/>} />
                <Route path="/report" element={<Report/>} />
                <Route path="/form" element={<RegisterForm/>} />
                <Route path="/reportPdf/:uuid" element={<ReportPdf/>} />
                <Route path="/topMethods/:uuid" element={<TopMethods/>} />
                <Route path="/topMethods" element={<TopMethods/>} />
                <Route path="/tone/:uuid" element={<Tone/>} />
                <Route path="/tone" element={<Tone/>} />
                <Route path="/coaching" element={<Coaching/>} />
                <Route path="/coaching/:uuid" element={<Coaching/>} />
                <Route path="/freelancing" element={<Freelancing/>} />
                <Route path="/freelancing/:uuid" element={<Freelancing/>} />
                <Route path="/marketing" element={<Marketing/>} />
                <Route path="/marketing/:uuid" element={<Marketing/>} />
                <Route path="/tonePaymentSuccess/:email" element={<ToneSuccess/>} />
                <Route path="/thanksSubmit/:type" element={<ThanksSubmit/>} />
                <Route path="*" element={<PageNotFound />} />

            </Routes>
            <Footer/>
        </Router>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
