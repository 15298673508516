import React, {useEffect, useState} from "react";
import axios from "axios";
import "./style.css";
import Form from "react-bootstrap/Form";

function Tone() {
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);


    }, [])
    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity()) {
            const answers = event.target.elements
            const formAnswers = {
                name: answers.name.value,
                lastName: answers.lastName.value,
                email: answers.email.value,
                tradingUserName: answers.tradingUserName.value,
                telegramUserName: answers.telegramUserName.value,
                phone: answers.phone.value,
                refferal: answers.refferal.value
            };
            let url = process.env.REACT_APP_API_URL + "/paypal-gate-tone?"
            for (const key in formAnswers) {
                url += `${key}=${formAnswers[key]}&`
            }
            url= url.slice(0, -1);
            window.location.replace(url);
        }
        setValidated(true);

    };
    return (

        <div>
            <nav className="navbar">
                <div className="container-fluid justify-content-start justify-content-xs-center">
                    <img src={require("./img/tone_3@3x.png")} width="50" className="ml-30 m-xs-0" alt=""></img>
                        <span className="logo">TONE VAYS</span>
                </div>
            </nav>


            <div className="bg3-gray">
                <div className="container-fluid bg-black p-sm-0">
                    <div className="main-container">
                        <div className="container-fluid img-frame1">
                            <div className="row">
                                <div className="col-12 frame1-1">
                                    Ready To Crack
                                    The <span className="color-yellow">Trading Income</span> Code?
                                </div>
                                <div className="col-12 frame1-2 my-3">
                                    Discover The Exact Step-By-Step Methods Used By Veteran Wall Street Traders Who
                                    Consistently
                                    Rake In
                                    Cold Hard Cash…And
                                </div>
                                <div className="col-12 frame1-3">
                                    Become Financially Free!
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="main-container">
                    <div className="container-fluid bg-white p-3 box-buyNow">
                        <div className="row py-3 px-4">
                            <div className="col-md-9 text-xs-center">
                                The famous <b>Technical Analysis & Risk/Position Management </b>
                                Webinar from Tone Vays is now available online
                            </div>
                            <div className="col-md-3 mt-xs-3 text-right text-xs-center">
                                <a className="btn btn-danger text-nowrap" href="#purchase-form">BUY NOW!</a>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-white mt-3 p-35">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="img-frame2 d-desktop"></div>
                                <img src={require("./img/img1_m_phone.png")} className="w-100 d-phone"></img>
                            </div>
                            <div className="col-md-7 mt-xs-3">
                                <div className="frame3-1">
                                    Are you tired of losing money trading stocks, bonds, ETFs, Forex, bitcoin and
                                    cryptos?
                                </div>
                                <div className="frame3-1 mt-3">
                                    Have you had some success trading in the past but for some reason your gains have
                                    not been
                                    consistent?
                                </div>
                                <div className="frame3-1 mt-3">
                                    Are your trades OK, but luck seems to always go against you and so your bottom line
                                    is not that
                                    great?
                                </div>
                                <div className="frame3-1 mt-3">
                                    Do you want to learn how to consistently trade for profits?
                                </div>
                                <div className="frame3-1 mt-3">
                                    Do you want to finally learn the fundamentals of trading THE RIGHT WAY?
                                </div>
                                <div className="frame3-1 mt-3">
                                    Do you want a proven step-by-step method to build your “trading income” from scratch
                                    so that you
                                    can enjoy more time doing what you love?
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg1-gray p-35 text-xs-center">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="img-frame3 d-desktop"></div>
                                <img src={require("./img/tone_img1_phone.png")} className="w-100 d-phone"></img>
                            </div>
                            <div className="col-md-8">
                                <div className="frame4-1 mt-xs-3">A Tone Vay’s</div>
                                <div className="frame4-2">Webinar</div>
                                <div className="frame4-3 mt-2">Technical Analysis & Risk/Position Management</div>
                                <div className="frame4-4 mt-3">In about <b>12 hours</b> total, Tone teaches you both the
                                    methods Wall
                                    Street
                                    traders use for
                                    unbelievable trading gains and also his personal risk management techniques that
                                    will keep you
                                    “in the game” long enough to get rich (if you apply all the principles correctly)!l
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid bg2-gray p-3 mt-4">
                            <div className="row p-3">
                                <div className="col-md-9 text-xs-center text-white font-calibri pr-60">
                                    For a time to be determined by Tone’s marketing team, the webinar will be available
                                    online
                                    for the low price of <b>$500</b>
                                </div>
                                <div className="col-md-3 text-center mt-xs-3">
                                    <a className="btn btn-danger text-nowrap" href="#purchase-form">Get it right
                                        now!</a>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-12 text-white text-right frame4-5 mt-3 ">The LIVE webinar was first
                                taught by Tone in
                                July 2020, but you can now get it online for a limited time!
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-white p-35 text-xs-center">
                        <div className="frame5-1 mt-xs-3">
                            Discover the EXACT techniques Tone used on Wall Street during his trading career to make
                            money for his
                            firm not just one year, not just two years, not just three years but 10 years in a row in
                            absolutely all
                            market conditions!
                        </div>
                        <div className="frame5-2 mt-3">
                            It’s not complicated, and it works like clockwork. All you need is a computer with internet
                            access and a
                            few hours a week!
                        </div>
                    </div>
                    <div className=" text-white py-3 frame6-1 text-xs-center">
                        Hear from our community members:
                    </div>
                    <div className="container-fluid bg-white">
                        <div className="row">
                            <div className="col-md-8 p-0">
                                <div className="frame7-4">
                                    <div className="frame7-1">
                                        <div>Daniel</div>
                                        <div><img src={require("./img/Frame 58.png")} alt=""></img></div>
                                    </div>
                                    <div className="frame7-2">35 year old - teacher</div>
                                    <div className="frame7-3">
                                        If you're tired of living paycheck to paycheck and have some money to invest,
                                        this program
                                        will
                                        open the world of trading for you so you can start trading smartly.
                                    </div>
                                </div>
                                <div className="frame7-4">
                                    <div className="frame7-1">
                                        <div>Maria</div>
                                        <div><img src={require("./img/Frame 58.png")} alt=""></img></div>
                                    </div>
                                    <div className="frame7-2">28 year old - hair-stylist</div>
                                    <div className="frame7-3">
                                        When you hear the word trading, people with a lot of money and very studious
                                        folks come to
                                        mind. With Tone’s program I have been able to understand that anyone can enter
                                        this world as
                                        long as they have the desire, the appropriate knowledge and the desire to work
                                        and learn.
                                    </div>
                                </div>
                                <div className="frame7-4">
                                    <div className="frame7-1">
                                        <div>Andrew</div>
                                        <div><img src={require("./img/Frame 58.png")} alt=""></img></div>
                                    </div>
                                    <div className="frame7-2">42 year old - firefighter</div>
                                    <div className="frame7-3">
                                        Tone’s program helps you build your financial intelligence so you can trade and
                                        invest
                                        wisely and provides trading strategies that work to accumulate wealth even in
                                        times of
                                        crisis.
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4 p-0 border-left border-xs-none">
                                <div className="frame7-4">
                                    <div className="frame7-1">
                                        <div>Jack</div>
                                        <div><img src={require("./img/Frame 58.png")} alt=""></img></div>
                                    </div>
                                    <div className="frame7-2">32 year old - unemployed</div>
                                    <div className="frame7-3">
                                        Fantastic primer to technical analysis! If you want to start trading, GET TONE’S
                                        SYSTEM! I
                                        started trading in the February 2021 bubble. Buying in at the highest, checking
                                        company
                                        financials, making sure they are profitable each quarter with increasing
                                        profits, making
                                        sure my PE ratios were not too high. All that stuff. Tons of hours researching
                                        etc. then
                                        coming up with strategies to dollar average. March came. Lost half my
                                        investment. Worked my
                                        way back up. May came. Lost another 75 percent. Something had to give. I took
                                        Tone’s course
                                        and I have been consistently making profits ever since.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-white p-35 mt-2">
                        <div className="row">
                            <div className="col-md-9 text-xs-center">
                                <div className="frame8-2"><b>Level up your trading!</b> <span>Limited time offer! Now @ $500</span>
                                </div>
                                <div className="frame8-1">An online Webinar on Technical Analysis & Risk/Position
                                    Management
                                </div>
                            </div>
                            <div className="col-md-3 text-right mt-xs-3 text-xs-center">
                                <a className="btn btn-danger text-nowrap" href="#purchase-form">BUY NOW!</a>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-black p-35 mt-2">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="border-yellow">
                                    <div className="frame9-1 mt-4">
                                        From the desk of
                                    </div>
                                    <div className="frame9-2">
                                        Tone Vays
                                    </div>
                                    <div className="text-center mt-5">
                                        <img src={require("./img/Tone_2.png")} className="w-100 d-desktop"></img>
                                        <img src={require("./img/tone_2_phone.png")} className="w-100 d-phone"></img>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="frame10-2 mt-xs-3">Fellow Trader,</div>
                                <div className="frame10-1">
                                    <div className="mt-4">
                                        Imagine what would happen if you could get a few hundred dollars (or even a few
                                        thousand
                                        dollars, depending on the initial value of your trading account) every day…


                                    </div>
                                    <div className="mt-4">
                                        Imagine waking up every morning knowing that your trading portfolio is growing
                                        consistently
                                        and predictably…


                                    </div>
                                    <div className="mt-4">
                                        Imagine knowing you can craft trading strategies that create profit AS YOU
                                        SLEEP…


                                    </div>
                                    <div className="mt-4">
                                        What would that do for your income?


                                    </div>
                                    <div className="mt-4">
                                        How much closer would you be to achieving financial freedom?

                                    </div>
                                    <div className="mt-4">
                                        Would this make it easier for you to take care of yourself and those around you?

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="bg2-gray mt-3 p-3 ">
                                    <div className="container-fluid mx-2">
                                        <div className="row p-3">
                                            <div className="col-md-9 text-xs-center text-white text-bold pt-3 font-18">
                                                Technical Analysis & Risk/Position Management @ $500
                                            </div>
                                            <div className="col-md-3 text-center mt-xs-3">
                                                <a className="btn btn-danger text-nowrap" href="#purchase-form">BUY
                                                    NOW!</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="frame11-1 mt-3">
                                    The LIVE webinar was first taught by Tone in July 2020, but you can now get it
                                    online for a
                                    limited time!

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid bg4-gray p-35">
                        <div className="row">
                            <div className="col-12 ">
                                <div className="frame12-1 mt-4">
                                    If you feel you’re spinning your wheels and no matter how hard you work or how many
                                    hours you
                                    dedicate to learning new techniques, your net worth is not growing as fast as it
                                    should…
                                </div>
                                <div className=" frame12-2 mt-5 text-bold">
                                    I am willing to bet that the real reason you are stuck is not what you think it is.
                                </div>
                                <div className="frame12-3 mt-4">
                                    <b>Yes,</b> the Federal Reserve has completely messed up the investment environment
                                    by flooding
                                    the system with liquidity…
                                </div>
                                <div className="frame12-3 mt-4">
                                    <b>Yes,</b>
                                    the competition is fierce…
                                </div>
                                <div className="frame12-3 mt-4">
                                    <b>Yes,</b>
                                    a pandemic has triggered so much money printing that it has altered the way people
                                    trade…
                                </div>
                                <div className="frame12-3 mt-4">
                                    <b>Yes,</b>
                                    inflation is affecting everyone now…
                                </div>
                                <div className="frame12-3 mt-4">
                                    <b>Yes,</b>
                                    central banks’ tightening in a coordinated effort is threatening equity markets
                                    everywhere
                                </div>
                                <div className="frame12-4 mt-4 text-bold">
                                    but are any of these factors the real reason you are stuck? I don’t think so! In
                                    fact, I
                                    believe...
                                </div>
                            </div>

                        </div>
                        <div className="row mt-4 bg-white py-3">
                            <div className="col-md-3">
                                <img src={require("./img/img2.png")} className="w-100 d-desktop" alt=""></img>
                                <img src={require("./img/img2_phone.jpg")} className="w-100 d-phone" alt=""></img>
                            </div>
                            <div className="col-md-9">
                                <div className="frame12-7 mt-4">
                                    the real reason
                                </div>
                                <div className="fram12-5 mt-3">
                                    your trading does not produce results…
                                </div>
                                <div className="fram12-5 mt-3">
                                    your account is not growing at the expected rate…
                                </div>
                                <div className="fram12-5 mt-3">
                                    you flat out are not making as much MONEY as you should be making usually comes down
                                    to one
                                    thing…
                                </div>
                                <div className="frame12-6 mt-4">
                                    You are not using statistics properly in your method!
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-white p-35 mt-2">
                        <div className="row">
                            <div className="col-12">
                                <div className="frame13-1 mt-3">
                                    Here’s the deal: most “traders” never take time to learn the fundamentals of trading
                                    properly.
                                </div>
                                <div className="frame13-2 mt-3">
                                    If you’ve ever traded stocks, ETFs, Forex, bitcoin or cryptos, tell me if this
                                    sounds familiar:
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="frame13-3 bg4-gray p-24 mt-3">
                                    As soon as you open a trade, the market moves against your position
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="frame13-3 bg4-gray p-24 mt-3">
                                    You close a trade with a little bit of profit, and the market moves in such a way
                                    your profit
                                    would have been much higher had you kept the position a few days longer
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="frame13-3 bg4-gray p-24 mt-3">
                                    You go back in on a trade you closed too soon, and the market moves and wipes out
                                    all previous
                                    gains, so now you are in the red and decide to “keep the asset for the long-term”
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="frame13-3 bg4-gray p-24 mt-3">
                                    You start a position with a small amount of money and finally things work for you so
                                    you decide
                                    to increase the position significantly - but as soon as you do that some “news”
                                    comes out of
                                    nowhere and wipes out all your gains, and then some
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="frame13-3 bg4-gray p-24 mt-3">
                                    You “miss the timing” for entering a trade and now you start chasing the asset,
                                    adding money
                                    incrementally - but when you are finally fully committed the market changes
                                    direction and your
                                    trade starts losing money
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <img src={require("./img/img4.png")} className="w-100 d-desktop" alt=""></img>
                                <img src={require("./img/img4_phone.png")} className="w-100 d-phone" alt=""></img>
                            </div>
                            <div className="col-12">
                                <div className="frame13-3 mt-4">
                                    So after any of these situations, you are left to ask yourself:
                                </div>
                                <div className="frame13-4 mt-3">
                                    What happened? Why do even seemingly good trades turn into losses?
                                </div>
                                <div className="frame13-5 mt-3">
                                    Most traders won’t admit it, but this is the question everyone is desperately trying
                                    to answer.
                                </div>
                                <div className="bg2-gray mt-4 p-35 box-buyNow">
                                    <div className="container-fluid">
                                        <div className="row ">
                                            <div className="col-md-9 text-xs-center text-white mt-2">
                                                <b>And the good news is,</b> I am about to do just that in the webinar!
                                            </div>
                                            <div className="col-md-3 text-right text-xs-center mt-xs-3">
                                                <a className="btn btn-danger text-nowrap" href="#purchase-form">BUY
                                                    NOW!</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-white p-35 mt-2 pt-0">
                        <div className="row">
                            <div className="col-12">
                                <div className="frame14-1 mt-24">
                                    It turns out, the secret to profitable trading has nothing to do with your skills of
                                    “timing the
                                    entry”
                                    of a trade. Yes, there are simple rules about entering a trade that everyone must
                                    know and
                                    follow, but
                                    at this point almost everyone does that and still 90% of traders out there lose
                                    money.
                                </div>
                                <div className="frame14-1 mt-24">
                                    So if the secret to profitable trading is not timing entries, then what is it?
                                </div>
                                <div className="frame14-1 mt-24">
                                    The liberating truth you must understand is this…
                                </div>
                                <div className="frame14-2 mt-24">
                                    There Is No Such Thing As A Timing Problem!
                                </div>
                                <div className="frame14-1 mt-24">
                                    I will say that again because it is important… <b className="fw-bold">There is no
                                    such thing as a timing
                                    problem.</b>
                                    Because with
                                    discipline and proper risk management, even terrible timing becomes inconsequential.
                                    You may not
                                    fully
                                    believe what I am about to say, but trust me... <b>timing trade entries is the easy
                                    part.</b>
                                </div>
                                <div className="frame14-3 mt-24">
                                    The more challenging part of the “puzzle” is what happens AFTER you enter a trade.
                                </div>
                                <div className="frame14-1 mt-24">
                                    To put it another way…
                                </div>
                                <div className="frame14-3 mt-24">
                                    The Real Secret To Trading Profitably Is Consistently Using Risk Management and
                                    “Having
                                    Statistics ON
                                    YOUR SIDE”
                                </div>
                                <div className="frame14-1 mt-24">
                                    I realize this is all a bit cryptic, so allow me to explain…
                                </div>
                            </div>

                        </div>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <img src={require("./img/img5.png")} alt="" className="w-100"></img>
                            </div>
                            <div className="col-md-8">
                                <div className="frame14-1 mt-xs-3">
                                    Risk management and “having statistics on your side” work hand in hand. Because if
                                    you employ
                                    proper risk management, you will never blow up your account by executing one bad
                                    trade. And this
                                    alone gives you time to place trade after trade until statistics ultimately tilt the
                                    game in
                                    your favor…… that is, if you use statistics correctly!
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="container-fluid bg-white p-35 mt-2">
                        <div className="row">
                            <div className="col-md-9 text-xs-center">
                                <div className="frame8-2">
                                    <div className="text-bold">
                                        Learn the fundamentals of trading and much more!
                                    </div>
                                    Limited time offer! Now @ $500
                                </div>
                            </div>
                            <div className="col-md-3 text-xs-center mt-xs-3 text-right mt-3">
                                <a className="btn btn-danger text-nowrap" href="#purchase-form">BUY NOW!</a>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid mt-2">
                        <div className="row">
                            <div className="col-md-6 bg-white p-35">
                                <div className="frame14-1">
                                    Can I be blunt with you?

                                    This is where most of the traders mess up!

                                </div>
                                <div className="frame15-1 mt-3">
                                    90% of traders lose money because they don't understand the fundamentals of trading,
                                    which come
                                    from statistics!
                                </div>
                                <div className="frame14-1 mt-3">
                                    Nearly all traders that come to me asking for help reviewing their trades have the
                                    exact same
                                    problem: their understanding of the fundamentals of trading is weak, and as such are
                                    using
                                    statistics poorly.

                                </div>
                                <div className="frame14-1 mt-3">
                                    To add insult to injury, their risk management lacks discipline and they invariably
                                    end up in
                                    losing situations!

                                </div>
                                <div className="frame14-1 mt-3">
                                    So if you want to consistently make profits in trading, your mission is simple…

                                </div>
                                <div className="frame14-1 mt-3">
                                    You need to use statistics correctly, and for that you need to relearn the
                                    fundamentals of
                                    trading THE RIGHT WAY…

                                </div>
                                <div className="frame14-1 mt-3">
                                    … and you need to use proper risk management. <br/> That’s it!


                                </div>
                                <div className="frame14-1 mt-3">
                                    Let me show you…


                                </div>
                            </div>
                            <div className="col-md-6 bg1-gray p-35">
                                <div>
                                    <img src={require("./img/img6.png")} className="w-100 d-desktop" alt=""></img>
                                    <img src={require("./img/img6_phone.png")} className="w-100 d-phone" alt=""></img>
                                </div>
                                <div className="frame15-2 mt-3">
                                    Thousands Of Hours Spent Trading, Hundreds Of Seven-Figure Trades, And Millions Of
                                    Dollars Made
                                    In Trades On Wall Street Reveal The Most Consistent Method Of Making Money In
                                    Trading
                                </div>
                                <div className="frame15-3 mt-3">
                                    When you’ve been trading long enough to survive “The Great Recession”, three bitcoin
                                    bear
                                    markets, a global pandemic and pretty much everything else that life has thrown at
                                    you over the
                                    last 15 years, and still ended up on top… you’re going to learn a thing or 1,000.

                                    <div className="mt-4">
                                        And make a big discovery along the way…
                                    </div>

                                </div>
                                <div className="mt-5">
                                    <a className="btn btn-danger text-nowrap w-100" href="#purchase-form">BUY NOW!</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid mt-2 bg-black p-35">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="frame16-1">
                                    This is not your average “how to make money trading” course. It is the exact same
                                    system I use
                                    for
                                    my own trading every single month, and it works for me so I know it will work for
                                    you.
                                </div>
                                <div className="frame16-1">
                                    After watching the approximately 12 hours of trading fundamentals and risk
                                    management
                                    techniques,
                                    you will walk away with a simple, actionable trading plan that will get you winning
                                    trades,
                                    consistently.

                                </div>
                                <div className="frame16-2">
                                    And you will always be able to refer back to all the building blocks and rules I
                                    explain during
                                    the
                                    webinar.

                                </div>

                            </div>
                            <div className="col-md-8">
                                <div className="frame16-3">
                                    It did not matter what the markets threw at us, because ultimately…
                                </div>
                                <div className="frame16-3">
                                    The ability to craft trading strategies that consistently increase the value of your
                                    trading
                                    account
                                    is the single most valuable skill that anyone can have.
                                </div>
                                <div className="frame16-3">
                                    Normally it would take years and even decades of practice and experimentation to
                                    become an
                                    expert at
                                    crafting trading strategies that consistently make profits.

                                </div>
                                <div className="frame16-3">
                                    Over the past decade I’ve developed a step-by-step method of trading that uses
                                    statistics THE
                                    RIGHT
                                    WAY by correctly employing the fundamentals of trading, and that includes risk
                                    management
                                    PROPERLY.

                                </div>
                                <div className="frame16-3">
                                    And this method can be taught to traders or people who want to learn trading just
                                    like you in a
                                    matter of hours… traders ready to unlock a consistent flow of winning trades and
                                    change their
                                    lives
                                    forever!
                                </div>
                                <div className="frame16-3">
                                    That is why I put together my <b>Technical Analysis & Risk/Position
                                    Management</b> Webinar.

                                </div>
                                <div className="frame16-3">
                                    I am so excited to share this program with you… because I know the power and
                                    potential it has
                                    for
                                    you as well as the impact it can have on your finances.

                                </div>
                                <div className="frame16-3">
                                    Imagine what is possible when I take you over a decade’s worth of experience in just
                                    12 hours
                                    and
                                    leave you ready to generate profits just like veteran Wall Street traders!


                                </div>
                                <div className="frame16-3">
                                    What would it mean for you to not just see these techniques and methods, but also
                                    take powerful
                                    action and apply them to your trading EVERY DAY?

                                </div>
                                <div className="frame16-3">
                                    Click the button below and join the elite group of people who have decided that they
                                    want to be
                                    part
                                    of the 10% winning group of traders!

                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 text-right">
                                <div className="d-flex flex-row-reverse mt-3">
                                    <div className="ml-15"><img src={require("./img/tone_3@3x.png")} width="84" alt=""></img></div>

                                    <div>
                                        <div className="titleSuccess">
                                            To your success,
                                        </div>
                                        <div className="signature">
                                            Tone
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-12">
                                <div className="bg2-gray mt-4 p-35 ">
                                    <div className="container-fluid mx-2">
                                        <div className="row">
                                            <div className="col-md-9 text-xs-center text-white box-buyNow">
                                                Get Tone Vays' <b>Technical Analysis & Risk/Position
                                                Management</b> Webinar right
                                                now!
                                            </div>
                                            <div className="col-md-3 text-xs-center text-right mt-xs-3">
                                                <a className="btn btn-danger text-nowrap " href="#purchase-form">BUY
                                                    NOW!</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid mt-2 bg4-gray p-35 box-guide">
                        <div className="row">
                            <div className="col-12 frame16-4 mb-4">
                                The Real Secret To Trading Profitably Is Consistently Using Risk Management and “Having
                                Statistics
                                ON YOUR SIDE”. These are only some of the things you will be learning:
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3 white-box-guide">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    How to avoid blowing up your account
                                </div>
                                <div className="frame17-2">
                                    The 3 essential parts of trading
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    The secret regulation that keeps you in a losing trade for 3 days if you do not meet
                                    this one
                                    condition
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    The secret way to avoid trading “scared money”
                                </div>
                                <div className="frame17-2">
                                    Hint: this gets personal
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    Why you cannot trust public indicators
                                </div>
                                <div className="frame17-2">
                                    Hint: they keep beginner traders dirt-poor
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    What moving averages to use and in which conditions
                                </div>
                                <div className="frame17-2">
                                    Hint: this is counterintuitive
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    What never to do ahead of earnings if you want to avoid blowing up your position
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    What never to do with your stop-loss if you took a profit on a spike in your
                                    direction
                                </div>

                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    The 4 parts of any successful technical trading system that could turn even a failed
                                    college
                                    student into a millionaire in a few months
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    Three powerful support and resistance methods to put in your back-pocket
                                </div>
                                <div className="frame17-2">
                                    Use in conjunction with your preferred trading technique for situations when extra
                                    clarity is a
                                    must
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    Five little known oscillators and why you absolutely need to understand their
                                    divergence concept in order to pick trades like a pro
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    The two rules that HAVE TO be followed when removing a stop-loss
                                </div>
                                <div className="frame17-2">
                                    The 3 essential parts of trading
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    When the Fed raises interest rates everyone’s investments lose value,
                                    right?...Wrong!
                                </div>
                                <div className="frame17-2">
                                    With Tone’s technical trading method you can be in and out of positions and make
                                    consistent
                                    gains regardless of the direction of the general market
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    The “gap cut” method to use when the market moves against you overnight
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    The sneaky psychological trick that gets your mind in the right frame for trading
                                </div>
                                <div className="frame17-2">
                                    Why using this simple hack is not as easy as it sounds
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    Doubling down on losing positions loses you money most of the time.
                                </div>
                                <div className="frame17-2">
                                    But did you know there are two exceptions to this?
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    What specific obstacles should you expect as a technical trader?
                                </div>
                                <div className="frame17-2">
                                    Find out in Tone’s presentation on Risk Management!
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    What should be your expected maximum loss under proper risk management and assuming
                                    “normal
                                    market conditions”?
                                </div>
                                <div className="frame17-2">
                                    Learn best practices straight from the horse’s mouth!
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    If you feel you lack the systematic knowledge needed to succeed in trading, this
                                    program is the
                                    answer you’ve been looking for
                                </div>
                                <div className="frame17-2">
                                    Tone’s workshop gets you to the right level of expertise in no time!
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    When your broker starts to feel he is bamboozled
                                </div>
                                <div className="frame17-2">
                                    How to make sure brokerages never get an extra dime in trading fees from you!
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    The quickest, easiest way to find a broker that won’t cost you an arm and a leg
                                </div>

                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    The truth about a starting budget needed for trading
                                </div>
                                <div className="frame17-2">
                                    Do you have to be rich to start trading? Or can you succeed even if you start small?
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    Better than HODL-ing bitcoin! For even bigger gains than bitcoin can offer
                                </div>
                                <div className="frame17-2">
                                    Check out Tone’s favorite indicators!
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    The single most important part of trading, the easiest to understand and the hardest
                                    to
                                    implement
                                </div>
                                <div className="frame17-2">
                                    Learn risk management from a veteran Wall Street trader that will show you how to
                                    consistently
                                    make (and keep) profits!
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid bg-white p-35 mt-3">
                            <div className="row">
                                <div className="col-md-9 text-xs-center">
                                    <div className="frame17-4">
                                        <div className="text-bold">
                                            And much more!
                                        </div>
                                        <div className="frame17-3">
                                            Imagine receiving all the value described above for the low price
                                            of <b>$500!</b>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 text-xs-center mt-xs-3 text-right mt-2">
                                    <a className="btn btn-danger text-nowrap " href="#purchase-form">BUY NOW!</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="container-fluid bg1-gray p-35 mt-2">
                        <div className="row">
                            <div className="col-md-4">
                                <img src={require("./img/tone_4.png")} className="w-100 border-yellow" alt=""></img>
                            </div>
                            <div className="col-md-8">
                                <div className="frame18-1 mt-xs-3">Who Is</div>
                                <div className="frame18-2 mt-2">Tone Vays?</div>
                                <div className="frame18-3 mt-2">
                                    Analyst, Derivatives Trader & Event Producer
                                </div>
                                <div className="frame18-4 mt-4">
                                    Tone has worked on Wall Street for 10 years prior to discovering Bitcoin. Since then
                                    he has been
                                    educating people on his YouTube Channel about the importance of this unique
                                    Financial
                                    Technology.
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12">
                                <div className="frame18-5">
                                    Tone holds a Masters Degree in Financial Engineering from Florida State University
                                    along with
                                    Bachelor Degrees in Mathematics and Geology.
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-4 mt-xs-3">
                                <img src={require("./img/tone_5.png")} className="w-100" alt=""></img>
                            </div>
                            <div className="col-md-4 mt-xs-3">
                                <img src={require("./img/tone_6.png")} className="w-100" alt=""></img>
                            </div>
                            <div className="col-md-4 mt-xs-3">
                                <img src={require("./img/tone_7.png")} className="w-100" alt=""></img>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-4 frame18-5">
                                Tone has been featured in several Documentaries like Magic Money & Bitcoin - Beyond the
                                Bubble and
                                several media appearances like CNBC, RT and more. He is an independent content creator
                                and the
                                producer of 3 events: Unconfiscatable, Understanding Bitcoin and The Financial Summit
                                for traders &
                                hedge funds.
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-black mt-2 p-35">
                        <div className="row">
                            <div className="col-12 frame19-1">
                                By this point you are probably wondering if Tone’s <b> Technical Analysis &
                                Risk/position Management
                                system </b> really works.
                            </div>
                            <div className="col-12 frame19-2 mt-3">
                                So rather than brag about the results people get with this, we will let our community
                                members
                                describe it in their own words. Here are some comments from a few people who either
                                attended the
                                live webinars or recently bought them
                            </div>
                            <div className="col-12 mt-3">
                                <div className="container-fluid bg2-gray ">
                                    <div className="row">
                                        <div className="col-md-6 p-0">
                                            <div className="frame7-4">
                                                <div className="frame19-3 frame7-1 mt-2">
                                                    <div>Mark</div>
                                                    <div><img src={require("./img/yellow-f.png")} alt=""></img></div>
                                                </div>
                                                <div className="frame19-4 mt-2">29 year old - management consultant
                                                </div>
                                                <div className="frame19-5 mt-2">
                                                    In terms of content, Tone’s system is a fantastic resource. It is
                                                    reasonably
                                                    accessible for a beginner, but goes into enough detail to satisfy
                                                    even a serious
                                                    trader who wants to learn more about chart analysis.
                                                </div>
                                            </div>
                                            <div className="frame7-4">
                                                <div className="frame19-3 frame7-1 mt-2">
                                                    <div>Matt</div>
                                                    <div><img src={require("./img/yellow-f.png")} alt=""></img></div>
                                                </div>
                                                <div className="frame19-4 mt-2">37 year old - physicist</div>
                                                <div className="frame19-5 mt-2">
                                                    The ultimate trading risk management - it will make you rethink your
                                                    whole risk
                                                    management plan and execution. Packed full of knowledge and great
                                                    tips for
                                                    managing risk. Personally I would recommend this to anyone who is
                                                    building a
                                                    trading plan, anyone who continues to blow accounts, anybody who
                                                    doesn’t
                                                    understand or implement risk management and it is great for
                                                    perspective for
                                                    those already managing their risk. Thanks, Tone
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-6 p-0 border-left">
                                            <div className="frame7-4">
                                                <div className="frame19-3 frame7-1 mt-2">
                                                    <div>Andrea</div>
                                                    <div><img src={require("./img/yellow-f.png")} alt=""></img></div>
                                                </div>
                                                <div className="frame19-4 mt-2">24 year old - nurse</div>
                                                <div className="frame19-5 mt-2">
                                                    This is a first rate guide to charting. I will need to watch it a
                                                    couple more
                                                    times to take it all in but it's very accessible and easy to
                                                    understand with
                                                    practical examples. I have gone through a large number of resources
                                                    on charting
                                                    and investing and this is possibly the most accessible system on
                                                    charting I have
                                                    come across.
                                                </div>
                                            </div>
                                            <div className="frame7-4 ">
                                                <div className="frame19-3 frame7-1 mt-2">
                                                    <div>Loren</div>
                                                    <div><img src={require("./img/yellow-f.png")} alt=""></img></div>
                                                </div>
                                                <div className="frame19-4 mt-2">53 year old - architect</div>
                                                <div className="frame19-5 mt-2">
                                                    Not only does Tone’s system give solid investing and trading advice,
                                                    but the
                                                    information on reading, deciphering and analyzing the various chart
                                                    trends and
                                                    asset behavior is invaluable. Immediately after I finished watching
                                                    this, I went
                                                    back through the high points to make sure I had the candlestick and
                                                    chart
                                                    patterns down to memory.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg2-gray mt-2 p-35 box-buyNow">
                                    <div className="container-fluid mx-2">
                                        <div className="row">
                                            <div
                                                className="col-md-9 text-xs-center text-white text-bold p-0 mt-2 font-opensans">
                                                <b>Invest in yourself</b>
                                            </div>
                                            <div className="col-md-3 text-xs-center mt-xs-3 text-right">
                                                <a className="btn btn-danger text-nowrap " href="#purchase-form">BUY
                                                    NOW!</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid mt-2 bg4-gray p-35 box-guide white-box-guide">
                        <div className="row">
                            <div className="col-12 frame16-4">
                                If you are still wondering if it would be a good fit for you, here are a few other
                                things you should
                                expect from Tone’s <b>Technical Analysis & Risk/Position Management system</b> Webinar
                            </div>
                        </div>
                        <div className="d-flex mt-4 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    How not to get wrecked
                                </div>
                                <div className="frame17-2">
                                    The special stop-loss trick used by veteran Wall Street traders
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    How to trade with small capital
                                </div>
                                <div className="frame17-2">
                                    The one sector where you can make it big by using these 3 rules
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    Little known secrets that could lead you to tremendous profits in bitcoin, even if
                                    the price
                                    drops to $3,000
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    Why Tone’s method works
                                </div>
                                <div className="frame17-2">
                                    Simple explanations in plain English that even a high-school dropout will understand
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    Why so many traders get wrecked
                                </div>
                                <div className="frame17-2">
                                    The 4 factors working against most technical traders
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    What Fibonacci retracements and extensions tell us about price movements and
                                    why most traders use them wrong
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    What never to do if overnight action goes against you in stock trading
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    Only the rich should trade, right? WRONG!
                                </div>
                                <div className="frame17-2">
                                    If you follow Tone’s risk management rules you will have a solid advantage and make
                                    consistent
                                    profits even when starting with relatively small accounts
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    The stock market is flashing DANGER by multiple metrics
                                </div>
                                <div className="frame17-2">
                                    Get Tone’s technical trading knowledge as soon as possible to protect yourself and
                                    not just stay
                                    afloat but prosper in the new environment created by inflation and rising interest
                                    rates
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    Are you behind on your financial goals?
                                </div>
                                <div className="frame17-2">
                                    Get an additional income stream by learning technical trading from the best!
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    Are you intimidated by all these concepts used in trading - such as candlesticks,
                                    chart
                                    patterns, moving averages, Fibonacci numbers etc.?
                                </div>
                                <div className="frame17-2">
                                    Tone’s system teaches you everything you need to know in a clear manner, and gives
                                    you a simple,
                                    easy to follow set of rules to make money FAST
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    Are you blaming yourself for not getting in on bitcoin sooner?
                                </div>
                                <div className="frame17-2">
                                    You can stop worrying about that right now, and join thousands of traders who caught
                                    up by
                                    making huge gains using Tone’s proven and easy to understand method
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    The “fear and greed” secret that few traders employ correctly
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    The sneaky way public algorithms on TradingView hide best information in plain sight
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    Level up your trading by addressing the fundamentals
                                </div>
                                <div className="frame17-2">
                                    with Tone’s easy to implement instructions that get results ASAP!
                                </div>
                            </div>
                        </div>
                        <div className="d-flex mt-3 bg-white p-3">
                            <div className="mx-3">
                                <img src={require("./img/Vector.png")} alt=""></img>
                            </div>
                            <div>
                                <div className="frame17-1">
                                    The quickest, easiest way to get started with technical trading while mastering the
                                    basics,
                                    guaranteed!
                                </div>
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="col-md-4  align-items-stretch">
                                <div className="mt-3 bg-white p-40 h-100">
                                    <div className="frame20-1">PLUS</div>
                                    <div className="frame20-2">
                                        how to use a losing trade to turn things around and lock-in outsized profits by
                                        using this
                                        little known principle
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 align-items-stretch">
                                <div className="mt-3 bg-white h-100 p-40">
                                    <div className="frame20-1">PLUS</div>
                                    <div className="frame20-2">
                                        the one indicator that will let you turn the tables on the Wall Street whales,
                                        and how to
                                        use it in conjunction with this one oscillator for maximum profits
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 align-items-stretch">
                                <div className="mt-3 bg-white h-100 p-40">
                                    <div className="frame20-1">PLUS</div>
                                    <div className="frame20-2">
                                        Tone’s complete list of candlesticks and chart patterns that will turn you into
                                        a winner in
                                        a matter of hours
                                    </div>
                                    <div className="frame20-3 mt-2">
                                        (as long as you do not forget to use discipline and apply rules consistently)
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid bg-white p-35 mt-3">
                            <div className="row">
                                <div className="col-md-9 text-xs-center">
                                    <div className="frame17-4">
                                        <div className="text-bold">
                                            And much more!
                                        </div>
                                        <div className="frame17-3">
                                            Imagine receiving all the value described above for the low price
                                            of <b>$500!</b>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 text-xs-center mt-xs-3 text-right mt-1">
                                    <a className="btn btn-danger text-nowrap" href="#purchase-form">BUY NOW!</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="container-fluid bg-black p-35 mt-2 text-white2">
                        <div className="frame21-1">
                            The truth is, as everything is becoming more expensive the current price will not last for
                            long. So grab
                            your copy of the “TECHNICAL ANALYSIS & RISK/POSITION MANAGEMENT” Webinar before the price
                            goes up (price
                            hike has been planned already by Tone’s marketing team, we are holding it off for now to
                            give a chance
                            to those who have been following Tone for a while).
                        </div>
                        <div className="frame21-2 mt-3">
                            This is what you will receive when you buy Tone’s <b>Technical Analysis & Risk/Position
                            Management
                            system:</b>
                        </div>
                        <div
                            className="container-fluid p-35 p-xs-0 border-xs-none border-gray text-white mt-4 box-guide">
                            <div className="container-fluid bg2-gray p-white-box">
                                <div className="row vertically-center">
                                    <div className="col-md-4">
                                        <img src={require("./img/tta_1.png")} className="w-100" alt=""></img>
                                    </div>
                                    <div className="col-md-8 mt-xs-3">
                                        <div className="frame21-3">Trading w/ <br/>
                                            Technical Analysis
                                        </div>
                                        <div className="frame21-4">6 hour course</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row dark-box">
                                <div className="col-md-6 align-items-stretch mb-3">
                                    <div className="d-flex mt-3 bg2-gray p-3 h-100">
                                        <div className="mx-3">
                                            <img src={require("./img/Vector.png")} alt=""></img>
                                        </div>
                                        <div>
                                            <div className="frame17-1">
                                                Intro to trading
                                            </div>
                                            <div className="frame17-2">
                                                Understanding the Risks, Why be a Trader
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 align-items-stretch mb-3">
                                    <div className="d-flex mt-3 bg2-gray p-3 h-100">
                                        <div className="mx-3">
                                            <img src={require("./img/Vector.png")} alt=""></img>
                                        </div>
                                        <div>
                                            <div className="frame17-1">
                                                Candlesticks
                                            </div>
                                            <div className="frame17-2">
                                                Shooting Star, Hammer, Doji, etc
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 align-items-stretch mb-3">
                                    <div className="d-flex mt-3 bg2-gray p-3 h-100">
                                        <div className="mx-3">
                                            <img src={require("./img/Vector.png")} alt=""></img>
                                        </div>
                                        <div>
                                            <div className="frame17-1">
                                                Candlestick Patterns
                                            </div>
                                            <div className="frame17-2">
                                                Ascending/Descending Triangles, Cups & Handles, etc
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 align-items-stretch mb-3">
                                    <div className="d-flex mt-3 bg2-gray p-3 h-100">
                                        <div className="mx-3">
                                            <img src={require("./img/Vector.png")} alt=""></img>
                                        </div>
                                        <div>
                                            <div className="frame17-1">
                                                Support/Resistance
                                            </div>
                                            <div className="frame17-2">
                                                Moving Averages, Trend-lines & Fibonacci, etc
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="d-flex mt-3 bg2-gray p-3">
                                        <div className="mx-3">
                                            <img src={require("./img/Vector.png")} alt=""></img>
                                        </div>
                                        <div>
                                            <div className="frame17-1">
                                                Momentum Indicators/Oscillators, Overlays & Divergence
                                            </div>
                                            <div className="frame17-2">
                                                MACD, RSI, CMF, ADX, Stochastic, Parabolic SAR, Ichimoku, Aroon,
                                                StochRSI, etc
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame21-1 mt-4">
                                Being a Technical Analysis Expert however, will NOT correlate to success without mastery
                                of Risk &
                                Position Management. Anyone that has lasted as a trader for more than 5 years will be
                                the first to
                                tell
                                you how important it is to make sure one mistake does not end your career.
                            </div>
                        </div>
                        <div className="container-fluid p-35 bg4-gray text-black mt-5 box-guide">
                            <div className="container-fluid bg-white p-white-box">
                                <div className="row vertically-center">
                                    <div className="col-md-4">
                                        <img src={require("./img/rpm_1.png")} className="w-100" alt=""></img>
                                    </div>
                                    <div className="col-md-8 mt-xs-3">
                                        <div className="frame21-3">
                                            Risk & Position <br/>
                                            Management
                                        </div>
                                        <div className="frame21-4">6 hour course</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row white-box-guide">
                                <div className="col-md-6 align-items-stretch mb-3">
                                    <div className="d-flex mt-3 bg-white p-3 h-100">
                                        <div className="mx-3">
                                            <img src={require("./img/Vector.png")} alt=""></img>
                                        </div>
                                        <div>
                                            <div className="frame17-1">
                                                Mental challenges of being a trader and getting ready for a trading day
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 align-items-stretch mb-3">
                                    <div className="d-flex mt-3 bg-white p-3 h-100">
                                        <div className="mx-3">
                                            <img src={require("./img/Vector.png")} alt=""></img>
                                        </div>
                                        <div>
                                            <div className="frame17-1">
                                                Discussing clear guidelines, structure & rules of successful traders
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 align-items-stretch mb-3">
                                    <div className="d-flex mt-3 bg-white p-3 h-100">
                                        <div className="mx-3">
                                            <img src={require("./img/Vector.png")} alt=""></img>
                                        </div>
                                        <div>
                                            <div className="frame17-1">
                                                Identifying optimal time to enter, exit & manage stop-losses of your
                                                positions
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 align-items-stretch mb-3">
                                    <div className="d-flex mt-3 bg-white p-3 h-100">
                                        <div className="mx-3">
                                            <img src={require("./img/Vector.png")} alt=""></img>
                                        </div>
                                        <div>
                                            <div className="frame17-1">
                                                Understanding Leverage
                                            </div>
                                            <div className="frame17-2">
                                                both in Crypto & in traditional markets like futures
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 align-items-stretch mb-3">
                                    <div className="d-flex mt-3 bg-white p-3 h-100">
                                        <div className="mx-3">
                                            <img src={require("./img/Vector.png")} alt=""></img>
                                        </div>
                                        <div>
                                            <div className="frame17-1">
                                                Best practices for account/money management
                                            </div>
                                            <div className="frame17-2">
                                                the most important thing in trading is capital preservation
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 align-items-stretch mb-3">
                                    <div className="d-flex mt-3 bg-white p-3 h-100">
                                        <div className="mx-3">
                                            <img src={require("./img/Vector.png")} alt=""></img>
                                        </div>
                                        <div>
                                            <div className="frame17-1">
                                                Position Management & quantifying the risk of a trade
                                            </div>
                                            <div className="frame17-2">
                                                both in Crypto & in traditional markets like futures
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="frame21-1 mt-3">
                                Understanding Risk & Position Management is extremely important. Knowing Technical
                                Analysis is
                                meaningless if proper trading practices are NOT followed. A trader with good Risk
                                Management will
                                usually outperform experts in Technical Analysis, fundamental researchers or a Quant who
                                thinks he
                                found the secret strategy that only makes winning trades (until it doesn't).
                            </div>
                            <div className="frame21-1 mt-3">
                                This course is meant to educate that with the right mental state, discipline and risk
                                understanding,
                                you should significantly improve your odds of success as a trader. It will also be your
                                biggest
                                challenge as a trader because it teaches HOW to trade, not WHY to take a trade. Knowing
                                what the
                                rules are is easy (ex: driving under the speed limit with your phone put away),
                                following best
                                practices is very hard.
                            </div>
                            <div className="frame21-5 bg-white p-40 mt-3">
                                Are you ready for this mental challenge? Few are, and that is why there is a common
                                phrase on Wall
                                Street: <b>"90% of traders lose 90% of their money in 90 days".</b>
                            </div>
                        </div>

                    </div>
                    <div className="container-fluid p-35 mt-2 text-white2 bg-black">
                        <div className="frame21-1 mt-3">
                            We believe you have three options at this point
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-4 align-items-stretch">
                                <div className="border-red h-100 p-4">
                                    <div><img src={require("./img/sad.png")} width="54" alt=""></img></div>
                                    <div className="frame22-1 mt-3">Ignore</div>
                                    <div className="frame22-2 mt-3">the “TECHNICAL ANALYSIS & RISK/POSITION MANAGEMENT”
                                        system and
                                        continue
                                        living and trading as you have done so far, with losses, frustration, “bad luck”
                                        and so
                                        forth
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 align-items-stretch">
                                <div className="border-yellow1 p-4 h-100">
                                    <div><img src={require("./img/so.png")} width="54" alt=""></img></div>
                                    <div className="frame22-1 mt-3">Try to put together</div>
                                    <div className="frame22-2 mt-3">
                                        all this information by yourself. It is possible but it took Tone years of
                                        practice on Wall
                                        Street to put it all together, so we expect it would take you a long time and a
                                        considerable
                                        amount of effort also
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-4 align-items-stretch">
                                <div className="border-green p-4 h-100">
                                    <div><img src={require("./img/happy.png")} width="54" alt=""></img></div>
                                    <div className="frame22-1 mt-3">Make the right decision</div>
                                    <div className="frame22-2 mt-3">
                                        Get the “TECHNICAL ANALYSIS & RISK/POSITION MANAGEMENT” system
                                    </div>
                                    <div className="frame22-3 mt-3">
                                        now for the low price we are keeping for a little while longer (price hike
                                        already scheduled
                                        by Tone’s marketing team, as we said). You will thank us for it!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-white p-35 mt-2">
                        <div className="row">
                            <div className="col-md-9 text-xs-center">
                                <div className="frame8-2"><b>Level up your trading!</b> <span>Limited time offer! Now @ $500</span>
                                </div>
                                <div className="frame8-1">An online Webinar on Technical Analysis & Risk/Position
                                    Management
                                </div>
                            </div>
                            <div className="col-md-3 text-xs-center mt-xs-3 text-right mt-3">
                                <a className="btn btn-danger text-nowrap" href="#purchase-form">BUY NOW!</a>
                            </div>
                        </div>
                    </div>
                    <div className="text-xs-center text-white py-3 frame6-1">
                        Hear from our community members:
                    </div>
                    <div className="container-fluid bg-white">
                        <div className="row">
                            <div className="col-md-6 p-0 align-items-stretch ">
                                <div className="frame7-4">
                                    <div className="frame7-1">
                                        <div>Alex</div>
                                        <div><img src={require("./img/Frame 58.png")} alt=""></img></div>
                                    </div>
                                    <div className="frame7-2">33 year old - graduate student</div>
                                    <div className="frame7-3">
                                        When it comes to trading it involves money and risk and it’s important to not
                                        let your
                                        emotions take over and make the decisions for you. Tone’s program helps the
                                        serious student
                                        of trading to identify where their psychological issues lie and, more
                                        importantly, provides
                                        an easy-to-understand model as to how to tackle them.
                                    </div>
                                </div>
                                <div className="frame7-4">
                                    <div className="frame7-1">
                                        <div>Rebecca</div>
                                        <div><img src={require("./img/Frame 58.png")} alt=""></img></div>
                                    </div>
                                    <div className="frame7-2">37 year old - accountant</div>
                                    <div className="frame7-3">
                                        If you're investing or trading, Tone’s system covers the principles of risk
                                        mitigation. It's
                                        a quick watch and gives you all the "check-points" to consider before you click
                                        the "submit"
                                        button on your trade orders.
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6 align-items-stretch p-0 border-left">
                                <div className="frame7-4">
                                    <div className="frame7-1">
                                        <div>George</div>
                                        <div><img src={require("./img/Frame 58.png")} alt=""></img></div>
                                    </div>
                                    <div className="frame7-2">41 year old - CEO</div>
                                    <div className="frame7-3">
                                        What I found most valuable was the guidance on determining entry and exit
                                        points, and
                                        recommendations for setting stops. This can be a real struggle for new traders,
                                        or even
                                        seasoned traders who struggle with deciding when to exit a winning trade.
                                    </div>
                                </div>
                                <div className="frame7-4 align-items-stretch">
                                    <div className="frame7-1">
                                        <div>John</div>
                                        <div><img src={require("./img/Frame 58.png")} alt=""></img></div>
                                    </div>
                                    <div className="frame7-2">45 year old - financial analyst</div>
                                    <div className="frame7-3">
                                        Must have resources for technical analysis and risk management. It's well worth
                                        the time and
                                        money.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="container-fluid bg-black p-4 mt-3" id="purchase-form">
                    <div className="main-container">
                        <div className="container-fluid bg-white p-35">
                            <div className="mb-3 d-flex justify-content-between vertically-center d-xs-block ">
                                <div className="frame14-2 py-2 ">
                                    Order form
                                </div>
                                <div className="mt-xs-3">
                                    <span className="text-danger">*</span>Indicates Required Field
                                </div>
                            </div>
                            <Form noValidate validated={validated} onSubmit={handleSubmit} id='tone-form'>
                                <div className="row frame-146">
                                    {/*<div className="form-group col-md-6 ">
                                        <div className="mt-4">
                                            <div className="d-flex justify-content-between my-2">
                                                <div>Payment type</div>
                                                <div className="text-danger">*</div>
                                            </div>
                                            <select id="PaymentType" className="form-select" required>
                                                <option selected disabled value="">Choose...</option>
                                                <option>...</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6 ">
                                        <div className="mt-4">
                                            <div className="d-flex justify-content-between my-2">
                                                <div>Georgaphical region</div>
                                            </div>
                                            <select id="region" className="form-select">
                                                <option selected disabled value="">Choose...</option>
                                                <option>...</option>
                                            </select>
                                        </div>
                                    </div>*/}
                                    <div className="form-group col-md-6 ">
                                        <div className="mt-4">
                                            <div className="d-flex justify-content-between my-2">
                                                <div>First name</div>
                                                <div className="text-danger">*</div>
                                            </div>
                                            <input name="name" type="text" className="form-control" id="inputFirstName" required></input>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6 ">
                                        <div className="mt-4">
                                            <div className="d-flex justify-content-between my-2">
                                                <div>Last name</div>
                                                <div className="text-danger">*</div>
                                            </div>
                                            <input name="lastName" type="text" className="form-control" id="LastName" required></input>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6 ">
                                        <div className="mt-4">
                                            <div className="d-flex justify-content-between my-2">
                                                <div>Trading view user name</div>
                                                <div className="text-danger">*</div>
                                            </div>
                                            <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon3"><img src={require("./img/con_1.png")}
                                                                                              width="28"
                                                                                              alt=""></img></span>
                                                <span className="input-group-text" id="basic-addon4">@</span>
                                                <input name="tradingUserName" type="text" className="form-control" id="inputTrading" required></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6 ">
                                        <div className="mt-4">
                                            <div className="d-flex justify-content-between my-2">
                                                <div>Telegram user name</div>
                                                <div className="text-danger">*</div>
                                            </div>
                                            <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon2"><img src={require("./img/con_2.png")}
                                                                                              width="28"
                                                                                              alt=""></img></span>
                                                <span className="input-group-text" id="basic-addon1">@</span>
                                                <input name="telegramUserName" type="text" className="form-control" id="Telegram" required></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-6 ">
                                        <div className="mt-4">
                                            <div className="d-flex justify-content-between my-2">
                                                <div>Email</div>
                                                <div className="text-danger">*</div>
                                            </div>
                                            <input name="email" type="email" className="form-control" id="Email" required></input>
                                        </div>
                                    </div>


                                    <div className="form-group col-md-6">
                                        <div className="mt-4">
                                            <div className="d-flex justify-content-between my-2">
                                                <div>Phone</div>
                                                <div className="text-danger">*</div>
                                            </div>
                                            <input name="phone" type="text" className="form-control" id="Phone" required></input>
                                        </div>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <div className="mt-4">
                                            <div className="d-flex justify-content-between my-2">
                                                <div>Referral</div>
                                            </div>
                                            <input name="refferal" type="text" className="form-control" id="Referral"></input>
                                        </div>

                                    </div>
                                    <div className="form-group col-12 text-center mt-4 ">
                                        <button type="submit" className="btn btn-danger text-bold">Purchase Webinar
                                        </button>
                                    </div>
                                </div>

                            </Form>


                        </div>
                    </div>

                </div>
                <div className="p-5 frame-last">
                    Disclaimer: Trading is very risky and learning these best practices on top of your technical
                    analysis knowledge
                    does NOT guaranty positive returns. You should only be trading with money you can afford to lose and
                    when
                    starting your trading career, consider your odds of success as if you are sitting down at a poker
                    game. The
                    longer you can last with proper risk management and following the rules you've studied, the greater
                    you increase
                    those odds of success but nothing is ever guaranteed.
                </div>
                {/*<div className="container-fluid bg-black p-5 frame-last text-center">*/}
                {/*    © 2022 ToneVays.com All Rights Reserved*/}
                {/*</div>*/}
            </div>



        </div>

    );
}

export default Tone;
