import Form from 'react-bootstrap/Form';
import InputGroup from "react-bootstrap/InputGroup";
import {RxEnvelopeClosed} from "react-icons/rx";
import girl from "../images/contact-girl.svg"
import mountain from "../images/mountain.svg";
import trees from "../images/trees-white.svg";
import Header from "./Header";
import { useNavigate } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import axios from "axios";
import TreesBg from "../components/TreesBg";

function Contact() {
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity()) {
            const answers = event.target.elements
            const body = {
                name: answers.name.value,
                email: answers.email.value,
                businessUrl: answers.businessUrl.value,
                description: answers.description.value,
            };

            axios.post(process.env.REACT_APP_API_URL + "/contact", body)
                .then(function (response) {
                    navigate('/thanksSubmit/contact');
                })
                .catch(function (error) {
                    navigate('/thanksSubmit/error');
                });
        }
        setValidated(true);

    };
    return (
        <div>
            <Header/>
            <div className="wrapper">
                <div className="w-screen">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="contact-form font-inter-14-400">
                                    <div className="font-sen-40-700 mb-4">
                                        Get in Touch
                                    </div>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Form.Group className="mb-3" controlId="contactForm.name">
                                            <Form.Label>Your name</Form.Label>
                                            <Form.Control name="name" required className="h-50" type="text" />
                                        </Form.Group>
                                        <Form.Group className="email-form mb-3">
                                            <Form.Label>
                                                Email address
                                            </Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text><RxEnvelopeClosed/></InputGroup.Text>
                                                <Form.Control name="email" required
                                                    className="h-50"
                                                    type="email"
                                                    id="inlineFormInputGroupEmail"
                                                    placeholder="Email address"
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="contactForm.business">
                                            <Form.Label>Business URL</Form.Label>
                                            <Form.Control type="text" className="h-50" name="businessUrl"/>
                                        </Form.Group>
                                        <Form.Group className="mb-4" controlId="contactForm.ControlTextarea1">
                                            <Form.Label>Tell us a bit about yourself</Form.Label>
                                            <Form.Control as="textarea" rows={4} name="description" required/>
                                        </Form.Group>
                                        <div className="text-right">
                                            <button type='submit' className="btn btn-theme-dark hover-btn-black-to-orange">Submit</button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                            <div className="col-md-6 text-right text-small-center mt-small-3">
                                <img src={girl} className="w-75 d-smm-none" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TreesBg/>
        </div>

    );
}

export default Contact;
