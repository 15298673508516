import Header from "./Header";
import girl from "../images/report-girl.svg"
import pdf from "../images/pdf-cover.svg"
import specialOffer from "../images/special-offer.svg";
import icon1 from "../images/icon1-report.svg";
import icon2 from "../images/icon2-report.svg";
import handShake from "../images/hand-shake.svg";
import React, {useEffect} from "react";
import trees from "../images/trees-gray.svg";


const Report = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    function loadPaypal(){
        window.location.replace(process.env.REACT_APP_API_URL+'/paypal-gate');
    }
    return (
        <div>
            <Header/>
            <div className="wrapper">
                <div className="w-screen">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <div style={{backgroundImage: `url(${girl})`}} className='bg-img p-box-report h-100'>

                                    <div className='border-left-orange font-sen-40-400 ps-4 mt-5'>
                                        Are you tired of living paycheck to paycheck?
                                    </div>
                                    <div className='border-left-orange font-sen-40-400 mt-5 ps-4'>
                                        Want to learn how to increase your income and achieve financial freedom?
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 p-box-report bg-gray700">
                                <div>
                                    <div className='font-inter-24-400 text-white'>
                                        Introducing the ultimate guide to making money online
                                    </div>
                                    <div className='font-inter-16-400 text-white mt-3'>
                                        The Online Shogun
                                    </div>
                                    <div className='font-sen-40-400 text-white mt-3'>
                                        Multiple Streams of Income report
                                    </div>
                                    <div className="container-fluid mt-3">
                                        <div className="row">
                                            <div className="col-md-4 ps-lg-0 mt-small-3">
                                                <img src={pdf} className='w-100' alt=""/>
                                            </div>
                                            <div className="col-md-8">
                                                <div className='ps-lg-3To receive our custom made recommendation for you, together with a comprehensive report describing'>
                                                    <div className='font-inter-16-400 text-white mt-lg-4 mt-small-3 '>
                                                        This comprehensive report contains multiple proven ways to make
                                                        money online, all in one place.
                                                    </div>
                                                    <div className="mt-3">
                                                        <button className="btn btn-theme-orange hover-btn-orange-to-black" onClick={loadPaypal}>BUY NOW</button>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='font-sen-28-400 mt-60'>
                                    Inside, you'll discover:
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className='number-report'>
                                    5 <sup>ways</sup>
                                </div>
                                <div className='font-inter-20-400'>
                                    little-known ways to <b>make money from home</b>, even if you have no experience or
                                    skills
                                </div>
                            </div>
                            <div className="col-md-3 mt-small-3">
                                <div className='number-report'>
                                    3 <sup>ways</sup>
                                </div>
                                <div className='font-inter-20-400'>
                                    to make insane amounts of <b>money while traveling</b>
                                </div>
                            </div>
                            <div className="col-md-3 mt-small-3">
                                <div className='number-report'>
                                    4 <sup>ways</sup>
                                </div>
                                <div className='font-inter-20-400'>
                                    to make <b>money by selling online</b>, without having to create your own products
                                </div>
                            </div>
                            <div className="col-md-3 mt-small-3">
                                <div className='number-report'>
                                    7 <sup>ways</sup>
                                </div>
                                <div className='font-inter-20-400'>
                                    to <b>make money on your own schedule</b> and say good-bye to that 9 to 5 job
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='font-sen-28-400 mt-4 text-right' style={{marginBottom: '100px'}}>
                                    And much more!
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="text-white bg-red700 p-special-offer">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-2 text-center">
                                                <img className="special-offer-img" src={specialOffer} alt=""/>
                                            </div>
                                            <div className="col-md-10">
                                                <div className='p-3 mt-4'>
                                                    <div className='font-sen-36-400'>
                                                        For a limited time, you can get this report for just <b>$29</b>
                                                    </div>
                                                    <div className='font-inter-16-400 mt-3'>That's a small price for the
                                                        knowledge and tools you need to increase
                                                        your income and achieve financial freedom.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='font-inter-20-400 my-5'>
                                    And remember, we do not just share these methods with you, ONLINE SHOGUN WILL ALSO
                                    SHOW YOU HOW TO USE THEM STEP-BY-STEP, either by working with you directly if you
                                    qualify, or by giving you exclusive access to buy our other products and services.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div style={{border: '1px solid #3F3F46', padding: '20px'}}>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-10">
                                                <div className='font-sen-28-400'>
                                                    Don't miss out - order your copy of the "Multiple Streams of Income"
                                                    report today and start making money online!
                                                </div>
                                            </div>
                                            <div className='col-md-2'>
                                                <div className="mt-3">
                                                    <button className="btn btn-theme-dark hover-btn-black-to-orange" onClick={loadPaypal}>BUY NOW</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="font-sen-36-400" style={{marginTop: '80px'}}>
                                    You might be wondering: will this really work for you?
                                </div>
                                <div className='font-inter-20-400 my-3'>
                                    Here’s the deal:
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="bg-gray200 mt-3 p-gr-box">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-1 text-lg-center">
                                                <img className='mt-4' src={icon1} alt=""/>
                                            </div>
                                            <div className="col-md-11">
                                                <div>
                                                    <div className='font-inter-20-400 mt-small-3'>
                                                        Anyone who tells you they can make money trading stocks or
                                                        investing in real estate without dedicating time to learn those
                                                        methods or having money to start with is lying through their
                                                        teeth!
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="bg-gray200 mt-3 p-gr-box">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-1 text-lg-center">
                                                <img className='mt-4' src={icon2} alt=""/>
                                            </div>
                                            <div className="col-md-11">
                                                <div>
                                                    <div className='font-inter-20-400 mt-small-3'>
                                                        We all agree that real estate investing is one of the best ways
                                                        to create and especially preserve wealth. Lots of rich people do
                                                        it. But how do you start if you have no money to begin with?
                                                        Well, we are only concerned with making money online so we do
                                                        not include real estate in our offerings.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='font-inter-20-400 text-grey-500 my-4'>
                                    Other than real estate, we consider every option of making money online when getting
                                    to know you. So you know something will work for you! And we do not leave that
                                    “something” to chance.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className=" mt-3 p-gr-box" style={{border: "1px solid #3F3F46"}}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-2 text-center">
                                                <img className='w-100' src={pdf} alt=""/>
                                            </div>
                                            <div className="col-md-10">
                                                <div className='ms-lg-3 d-flex align-items-center justify-content-center h-100'>
                                                    <div>
                                                        <div className='font-inter-20-400 mt-small-3'>
                                                            To receive our custom made recommendation for you, together with
                                                            a comprehensive report describing all our methods that have
                                                            created millions for others just like you click the button
                                                            below.
                                                        </div>
                                                        <div className="mt-3">
                                                            <button className="btn btn-theme-orange hover-btn-orange-to-black" onClick={loadPaypal}>BUY NOW</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="bg-gray700 p-gr-box mt-4 text-white">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-2 text-center">
                                                <img className='w-handshake' src={handShake} alt=""/>
                                            </div>
                                            <div className="col-md-10">
                                                <div className='ms-lg-3'>
                                                    <div className='font-inter-14-400 mt-5 mt-lg-3'>
                                                        Our “Multiple Streams Of Income” report will also contain a link
                                                        to the Online Shogun application form where we will ask for your
                                                        information so that we can decide if you are accepted to work
                                                        directly with us.
                                                    </div>
                                                    <div className='font-inter-24-400 mt-3'>
                                                        For a limited number of people we offer the opportunity to work
                                                        directly with us and make money alongside us from DAY ONE!
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="w-screen p-black-bottom">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <div className='font-inter-24-400'>
                                    Think of it this way
                                </div>
                                <div className='font-sen-36-400 mt-3'>
                                    Can you close deals?
                                </div>
                                <div className='font-inter-16-400 mt-3'>
                                    We trust you if you say so.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='font-inter-20-400 bg-gray200 p-4 mt-4'>
                                    We bring you a few prospective clients ready to close, with a deal ready to be
                                    introduced to them.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='font-inter-20-400 bg-gray200 p-4 mt-4'>
                                    We give you the chance to close the deal. If you are successful, you walk away with
                                    INSANE amounts of money. If you mess up, you lose nothing other than some
                                    confidence, maybe.
                                </div>
                            </div>
                        </div>
                        <div className="row mt-48">
                            <div className="col">
                                <div className='font-sen-36-400'>
                                    Do you hate sales but you are good with people and can bring a prospect to the
                                    table?
                                </div>
                                <div className='font-inter-16-400 mt-3'>
                                    This is valuable too
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='font-inter-20-400 bg-gray200 p-4 mt-4'>
                                    We show you what type of client we want, we teach you what to say and you bring the
                                    prospective client to have a sales call with our experienced team. If we close,
                                    again you walk away with INSANE amounts of money.
                                </div>
                            </div>
                        </div>
                        <div className="row mt-48">
                            <div className="col">
                                <div className='font-sen-36-400'>
                                    Do you not really enjoy talking to people in person or on the phone but you enjoy
                                    communication by email or chat?
                                </div>
                                <div className='font-inter-16-400 mt-3'>
                                    Great, this is also useful.
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className='font-inter-20-400 bg-gray200 p-4 mt-4'>
                                    We identify target companies and teach you how to approach them, get decision makers
                                    to respond and bring them to a sales call with us. If we close the deal… you guessed
                                    it: you walk away with INSANE amounts of money.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bg-gray700 text-white p-black-bottom'>
                    <div className='w-screen'>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-10">
                                    <div className='pe-lg-4'>
                                        <div className='font-sen-28-400 mt-small-3'>
                                            The link to the application form to work directly with us and tap into our
                                            network of experts is included in the Online Shogun "Multiple Streams of Income"
                                            report.
                                        </div>
                                        <div className='font-inter-20-400 mt-32'>
                                            You get not just our comprehensive report with methods for making money online,
                                            but you also get a chance to have a team of experts in your corner from DAY ONE,
                                            if you are accepted into our program.
                                        </div>
                                        <div className='font-sen-28-400 mt-32'>
                                            Do not delay, the number of those who will be accepted is limited because we
                                            cannot manage an unlimited number of people.
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-2 mt-small-3">
                                    <div className='d-flex justify-content-center align-items-center h-100'>
                                        <div>
                                            <img src={pdf} className='w-100' alt=""/>
                                            <div className="mt-4 mt-lg-3 w-100">
                                                <button className="btn btn-theme-orange hover-btn-orange-to-black" onClick={loadPaypal}>BUY NOW</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-smm-none'>
                    <img className="w-100" src={trees} alt=""/>
                </div>
            </div>
        </div>
    );
}

export default Report
