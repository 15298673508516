import React, {useEffect, useState} from "react";
import SecondHeader from "./SecondHeader";
import logo from "../images/navbar-brand.svg";
import Nav from "react-bootstrap/Nav";
import {LinkContainer} from "react-router-bootstrap";

function Terms() {
    const [active, setActive] = useState('Acceptance');

    function scrollTo(id) {
        setActive(id)
        document.getElementById(id).scrollIntoView({block: "center"});
    }

    useEffect(() => {
        window.scrollTo(0, 0);


    }, [])
    return (
        <div>
            <div className='d-just-mobile'>
                <SecondHeader/>
            </div>
            <div className=" px-small-3">
                <div className="w-screen">
                    <div className="terms-page">
                        <div className="sidebar-terms">
                            <div className='mb-5'>
                                <LinkContainer to="/">
                                    <Nav.Link>
                                        <img src={logo} alt=""/>
                                    </Nav.Link>
                                </LinkContainer>

                            </div>
                            <div className="terms-list mt-3">
                                <a className={active === 'Acceptance' ? 'active' : ''}
                                   onClick={() => scrollTo('Acceptance')}>Acceptance of terms and conditions</a>
                                <a onClick={() => scrollTo('Product')}
                                   className={active === "Product" ? 'active' : ''}>Product descriptions</a>
                                <a onClick={() => scrollTo('Pricing')}
                                   className={active === "Pricing" ? 'active' : ''}>Pricing and availability</a>
                                <a onClick={() => scrollTo('Order')}
                                   className={active === "Order" ? 'active' : ''}>Order processing</a>
                                <a onClick={() => scrollTo('Payment')}
                                   className={active === "Payment" ? 'active' : ''}>Payments</a>
                                <a onClick={() => scrollTo('Shipping')}
                                   className={active === "Shipping" ? 'active' : ''}>Shipping</a>
                                <a onClick={() => scrollTo('Returns')}
                                   className={active === "Returns" ? 'active' : ''}>Returns and exchange</a>
                                <a onClick={() => scrollTo('Disclaimer')}
                                   className={active === "Disclaimer" ? 'active' : ''}>Disclaimer of warranties</a>
                                <a onClick={() => scrollTo('Limitation')}
                                   className={active === "Limitation" ? 'active' : ''}>Limitation of liability</a>
                                <a onClick={() => scrollTo('conditions')}
                                   className={active === "conditions" ? 'active' : ''}>Changes to terms and conditions</a>
                                <a onClick={() => scrollTo('Governing')}
                                   className={active === "Governing" ? 'active' : ''}>Governing law</a>
                                <div style={{marginBottom: '150px'}}></div>
                            </div>
                        </div>
                        <div className="main-terms">
                            <div className="font-inter-36-700">Terms and conditions</div>
                            <div id="Acceptance" className="mt-conditions">
                                <div className="font-inter-24-400">1. Acceptance of terms and conditions</div>
                                <div className="font-inter-16-400 mt-inner-conditions">By using the website, users agree to be bound by
                                    the terms and conditions set forth.
                                </div>
                            </div>
                            <div id="Product" className="mt-conditions">
                                <div className="font-inter-24-400">2. Product descriptions</div>
                                <div className="font-inter-16-400 mt-inner-conditions">
                                    The website strives to be as accurate as possible. However, the website does not
                                    guarantee that product descriptions or other content are accurate, complete,
                                    reliable, current or error-free.
                                </div>
                            </div>
                            <div id="Pricing" className="mt-conditions">
                                <div className="font-inter-24-400">3. Pricing and availability</div>
                                <div className="font-inter-16-400 mt-inner-conditions">Prices and availability of products on the
                                    website are subject to change without notice.
                                </div>
                            </div>
                            <div id="Order" className="mt-conditions">
                                <div className="font-inter-24-400">4. Order processing</div>
                                <div className="font-inter-16-400 mt-inner-conditions">The website reserves the right to refuse any
                                    order placed for any reason.
                                </div>
                            </div>
                            <div id="Payment" className="mt-conditions">
                                <div className="font-inter-24-400">5. Payment</div>
                                <div className="font-inter-16-400 mt-inner-conditions">Users agree to pay the amount that is specified
                                    for the products they order. The website accepts several forms of payment, including
                                    credit cards and PayPal.
                                </div>
                            </div>
                            <div id="Shipping" className="mt-conditions">
                                <div className="font-inter-24-400">6. Shipping</div>
                                <div className="font-inter-16-400 mt-inner-conditions">The website ships products to the addresses
                                    specified by the user. Shipping costs and delivery times will vary depending on the
                                    product and location.
                                </div>
                            </div>
                            <div id="Returns" className="mt-conditions">
                                <div className="font-inter-24-400">7. Returns and exchanges</div>
                                <div className="font-inter-16-400 mt-inner-conditions">All sales are final. 
                                No returns or exchanges are allowed. There will be no refunds.
                                </div>
                            </div>
                            <div id="Disclaimer" className="mt-conditions">
                                <div className="font-inter-24-400">8. Disclaimer of warranties</div>
                                <div className="font-inter-16-400 mt-inner-conditions">The website makes no representations or
                                    warranties of any kind, express or implied, as to the operation of the website or
                                    the information, content, materials or products included on the website.
                                </div>
                            </div>
                            <div id="Limitation" className="mt-conditions">
                                <div className="font-inter-24-400">9. Limitation of liability</div>
                                <div className="font-inter-16-400 mt-inner-conditions">The website shall not be liable for any damages
                                    of any kind arising from the use of the website, including but not limited to
                                    direct, indirect, incidental, punitive, and consequential damages.
                                </div>
                            </div>
                            <div id="conditions" className="mt-conditions">
                                <div className="font-inter-24-400">10. Changes to terms and conditions</div>
                                <div className="font-inter-16-400 mt-inner-conditions">The website reserves the right to change the
                                    terms and conditions at any time without notice.
                                </div>
                            </div>
                            <div id="Governing" className="mt-conditions">
                                <div className="font-inter-24-400">11. Governing law</div>
                                <div className="font-inter-16-400 mt-inner-conditions">These terms and conditions shall be governed by
                                    and construed in accordance with the laws of the state of Delaware.
                                </div>
                            </div>
                            <div style={{marginBottom: '100px'}}></div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    );
}

export default Terms;
